import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { IconButton, Collapse} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

class Alerta extends React.Component {
  render() {
    return(
      <div className={this.props.classes.root}>
        <Collapse in={this.props.mostrarAlerta}>
          <Alert
            variant="filled"
            severity={this.props.tipoAlerta}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  this.props.cerrarAlerta()
                }}
                >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {this.props.texto}
          </Alert>
        </Collapse>
      </div>
    )
  }
}

export default withStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  },
}))(Alerta)