import React, { Component } from 'react';
import './Solicitudes.css';
import { withRouter } from 'react-router-dom';
import axios from '../utils/axios';
import jwt from 'jwt-decode';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import BookmarkIcon from '@material-ui/icons/Bookmark';

import Pagination from '@material-ui/lab/Pagination';

import tgTrianguloBlanco from '../assets/img/icons/tgTrianguloBlanco.svg';
import tgLetraColor from '../assets/img/icons/tgLetraColor.svg';

import Layout from '../components/layout/Layout';
import BotonCirculoAtrasVerdeLink from '../components/general/BotonCirculoAtrasVerdeLink';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

/**
 * Items por pagina
 */
const itemsPorPagina = 5;

class Solicitudes extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedIndex: 1,
            listaSolicitudes: [],
            abrirProgreso: false,
            pagina: 1,
            numeroDePaginas: 0
        }
    }

    componentDidMount() {
        /**
         * Verificar token
         */
        if(this.invalidToken()){
            this.logoutByTokenExpired();
        }

        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        const dataUser = localStorage.getItem('data_user');
        const usuarioJSON = JSON.parse(dataUser).data;
        const usuarioCod = usuarioJSON.usuarioCod;
        /**
         * Get solicitudes por usuario
         */
        this.getSolicitudesPorUsuario(usuarioCod, authString, this.state.pagina - 1);
    }
    /**
     * Get solicitudes por usuario
     */
    getSolicitudesPorUsuario(usuarioCod, authString, numeroPagina){
        // Abrir vistar progresos
        this.abrirVistaProgreso(true);

        axios.get(`usuario/solicitudes/usuario/${usuarioCod}/${numeroPagina}/${itemsPorPagina}`, { headers: { Authorization: authString } })
        .then(response =>{
            // Cerrar vista progreso
            this.abrirVistaProgreso(false);

            this.setState({
                listaSolicitudes: response.data,
                numeroDePaginas: Math.ceil(Number(response.headers['x-total-count']) / itemsPorPagina)
            });
        }).catch(error => {
            // Cerrar vista progreso
            this.abrirVistaProgreso(false);
            console.log(error);
        });
    }
    /**
     * Select item solicitud
     */
    selectItemSolicitud = (event, valor) => {
        /**
         * Verificar token
         */
        if(this.invalidToken()){
            this.logoutByTokenExpired();
        }
        const solicitudCod = valor;
        this.props.history.push(`solicitudes/${solicitudCod}`);
    }
    /**
     * Cambiar de pagina
     */
    cambiarPagina = (event, value) => {
        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        const dataUser = localStorage.getItem('data_user');
        const usuarioJSON = JSON.parse(dataUser).data;
        const usuarioCod = usuarioJSON.usuarioCod;
        this.setState({pagina: value});
        /**
         * Get solicitudes por usuario
         */
        this.getSolicitudesPorUsuario(usuarioCod, authString, value - 1);
    };

    /**
     * Abrir vista progreso
     */
    abrirVistaProgreso = (abrir) => {
        this.setState({abrirProgreso: abrir});
    }
    /**
     * ******************************
     * Verificar validacion de token
     * ******************************
     */
    invalidToken = () => {
        const tokenId = localStorage.getItem("tokenuser");
        const decodeToken = jwt(tokenId);
        // Expiracion del token en segundos
        const expTokenSegundos = decodeToken.exp;
        // Fecha actual en segundos
        const todaySegundos = Date.now() / 1000;
        // 1590602256.671 > 1590601904
        return todaySegundos > expTokenSegundos;
    }
    /**
     * logout by token expired
     */
    logoutByTokenExpired = () => {
        localStorage.removeItem('tokenuser');
        localStorage.removeItem('data_user');
        localStorage.removeItem('lista_servicios');
        localStorage.removeItem('datos_empresa');
        this.props.history.push("/");
    }
    
    render() {
     
        return (
            <Layout>
                <Backdrop style={{zIndex: '1', color:'white'}} open={this.state.abrirProgreso}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="solicitudesContenedorDegradado">
                    <Container>
                        <div className="solicitudesContenedorTexto">
                            <div>
                                <BotonCirculoAtrasVerdeLink ruta={"/home"}/>
                            </div>
                            <div className="solicitudesContenedorTitulo">
                                <h2 className="solicitudesEstiloTitulo">Monitorizar</h2>
                            </div>
                            <div className="solicitudesSubtitulo">
                                <h2 className="solicitudesEstiloSubtitulo">
                                    Lista de solicitudes de servicio
                                </h2>
                            </div>
                        </div>
                    </Container>
                </div>
                {
                    /**
                     * Lista de solicitudes
                     */
                }
                <div>
                    <Container>
                        <div style={{paddingTop: "20px", marginBottom: "20px"}}>
                            <div className="solicitudesContenedorTituloPaginacion">
                                <div style={{marginBottom: "10px"}}>
                                    <MuiThemeProvider theme={theme}>
                                        <Typography variant="h4" component="h2">
                                            Solicitudes
                                        </Typography>
                                    </MuiThemeProvider>
                                </div>
                                <div className="solicitudContenedorPaginacion">
                                    <Pagination
                                            count={this.state.numeroDePaginas}
                                            page={this.state.pagina}
                                            onChange={this.cambiarPagina} />
                                </div>
                            </div>                         
                            <Divider/>
                        </div>
                        <div className="solicitudesContenedorListaOImagen">
                        {
                        (this.state.listaSolicitudes.length > 0)
                        ?
                            <div className="solicitudesContenedorLista">
                                <List component="nav" aria-label="solicitudes">
                                    {
                                        this.state.listaSolicitudes.map(solicitud => {
                                            return(
                                                <div key={solicitud.cod}>
                                                    <ListItem
                                                        button
                                                        onClick={(event) => this.selectItemSolicitud(event, solicitud.cod)}>
                                                        <ListItemIcon>
                                                            <React.Fragment>
                                                                {
                                                                    (solicitud.pagado)
                                                                    ?
                                                                    <CheckBoxIcon style={{ color: "#00A775" }}/>
                                                                    :
                                                                    <CheckBoxOutlineBlankOutlinedIcon />
                                                                }
                                                                {
                                                                    (solicitud.cancelado)
                                                                    ?
                                                                    <BookmarkIcon style={{ color: "#f44336" }}/>
                                                                    :
                                                                    <BookmarkIcon style={{ color: "#FAA634" }}/>
                                                                }
                                                            </React.Fragment>
                                                        </ListItemIcon>
                                                        <Grid container direction="column">
                                                            <Grid item xs={12}>
                                                                <ListItemText
                                                                primary={<p style={{fontSize:"15px", marginBottom:"0px"}}>COD {solicitud.cod} / {solicitud.fechaCreacion}</p>}
                                                                secondary={
                                                                    <React.Fragment>
                                                                        <Typography
                                                                            component="span"
                                                                            variant="body2"
                                                                            style={{display: "inline", marginRight: "20px"}}
                                                                            color="textPrimary">
                                                                            {solicitud.nombre}
                                                                        </Typography>
                                                                        {solicitud.detalle.slice(0, 50)}
                                                                    </React.Fragment>
                                                                }
                                                                />
                                                            </Grid>
                                                            {
                                                                (solicitud.asigando)
                                                                ?
                                                                <Grid item xs={12}>
                                                                    <Chip label={solicitud.nombreTecncio.substring(0, 20)} style={{ backgroundColor: "#00A775", color: "#FFFFFF" }} size="small" avatar={<Avatar src={tgTrianguloBlanco} />} />
                                                                </Grid>
                                                                :
                                                                <div></div>
                                                            }
                                                        </Grid>
                                                    </ListItem>
                                                    <Divider/>
                                                </div>
                                            )
                                        })
                                    }
                                </List>
                            </div>
                        :
                            <div className="solicitudesContenedorImagenFondo">
                                <img src={tgLetraColor} className="solicitudesImagenFondo" alt="Logo TecniGo"/>
                            </div>
                    }
                    </div>
                    </Container>
                </div>
            </Layout>
        )
    }
}

export default withRouter(Solicitudes)


