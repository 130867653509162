import React from 'react';
import { NavLink,useHistory} from 'react-router-dom';

import './Header.css';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DescriptionIcon from '@material-ui/icons/Description';
import Divider from '@material-ui/core/Divider';
import TimelineIcon from '@material-ui/icons/Timeline';
import Typography from '@material-ui/core/Typography';
import { responsiveFontSizes, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import BotonVerde from '../general/BotonVerde';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export default function AuthHeader(props) {
  let history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  /**
   * Datos del usuario
   */
  const [usuario] = React.useState(JSON.parse(localStorage.getItem('data_user')).data);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  /**
   * Cerrar sesion
   */
  const logout = () => {
      localStorage.removeItem('tokenuser');
      localStorage.removeItem('data_user');
      localStorage.removeItem('lista_servicios');
      localStorage.removeItem('datos_empresa');
      history.push("/")
  }
  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose} >

      <div style={{margin: '20px'}}>
        <MuiThemeProvider theme={theme}>
          <Typography variant="button" component="p" style={{textOverflow: 'ellipsis', overflow: 'hidden', width: '260px', whiteSpace: 'nowrap'}}>
            {(usuario.usuarioNombre && usuario.usuarioApellido)? usuario.usuarioNombre + " " + usuario.usuarioApellido : 'TG'}
          </Typography>
          <Typography variant="body1" component="p" style={{textOverflow: 'ellipsis', overflow: 'hidden', width: '260px', whiteSpace: 'nowrap', color:'#455a64'}}>
            {(usuario.usuarioCorreo)? usuario.usuarioCorreo : ''}
          </Typography>
        </MuiThemeProvider>
      </div>
      <Divider />
      <NavLink to="/home" className="headerHomeEstiloNav" style={{width: '100%'}}>
        <MenuItem onClick={handleMenuClose}>
          <IconButton aria-label="Inicio" color="inherit">
            <HomeIcon style={{color:"#3B3092"}} />
          </IconButton>
          Inicio
        </MenuItem>
      </NavLink>
      <NavLink to="/reporte" className="authHeaderEstiloNavMovil" style={{width: '100%'}}>
        <MenuItem onClick={handleMenuClose}>
          <IconButton aria-label="Reporte" color="inherit">
            <TimelineIcon style={{color:"#3B3092"}}/>
          </IconButton>
          Reporte
        </MenuItem>
      </NavLink>
      <NavLink to="/perfil" className="authHeaderEstiloNavMovil" style={{width: '100%'}}>
        <MenuItem onClick={handleMenuClose}>
          <IconButton aria-label="Inicio" color="inherit">
            <PersonIcon style={{color:"#3B3092"}}/>
          </IconButton>
          Perfil
        </MenuItem>
      </NavLink>
      <MenuItem onClick={logout}>
        <IconButton aria-label="Salir" color="inherit">
          <ExitToAppIcon style={{color:"#E2445C"}} />
        </IconButton>
        <p className="authHeaderEstiloNavMovil" style={{color: "#E2445C"}}>Salir</p>
      </MenuItem>

    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem onClick={handleMobileMenuClose}>
        <NavLink to="/home" className="headerHomeEstiloNav" style={{width: '100%'}}>
          <IconButton aria-label="Inicio" color="inherit">
            <HomeIcon style={{color:"#3B3092"}} />
          </IconButton>
          Inicio
        </NavLink>
      </MenuItem>
      <NavLink to="/solicitudes" className="authHeaderEstiloNavMovil" style={{width: '100%'}}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton aria-label="Solicitudes" color="inherit">
            <DescriptionIcon style={{color:"#3B3092"}} />
          </IconButton>
          Solicitudes
        </MenuItem>
      </NavLink>
      <NavLink to="/reporte" className="authHeaderEstiloNavMovil" style={{width: '100%'}}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton aria-label="Reporte" color="inherit">
            <TimelineIcon style={{color:"#3B3092"}}/>
          </IconButton>
          Reporte
        </MenuItem>
      </NavLink>
      <NavLink to="/perfil" className="authHeaderEstiloNavMovil" style={{width: '100%'}}>
        <MenuItem onClick={handleMobileMenuClose}>
          <IconButton aria-label="Perfil" color="inherit">
            <PersonIcon style={{color:"#3B3092"}}/>
          </IconButton>
          Perfil
        </MenuItem>
      </NavLink>
      <MenuItem onClick={logout}>
        <IconButton aria-label="Salir" color="inherit">
          <ExitToAppIcon style={{color:"#E2445C"}}/>
        </IconButton>
        <p className="authHeaderEstiloNavMovil" style={{color: "#E2445C"}}>Salir</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" style={{ backgroundColor: '#343D8E', padding: "5px" }}>
        <Toolbar>
          {
            (props.paginaPrincipal)?
            <>
            {
              (props.usuarioPerteneceEmpresa)?
              <>
                <NavLink to="/home"> 
                  <MuiThemeProvider theme={theme}>
                    <Typography variant="button" component="p" className="authHeaderEstiloNombreResponsive">
                      {(usuario.usuarioNombre)? usuario.usuarioNombre.toUpperCase().substring(20,0) : 'TecniGo'}
                    </Typography>
                  </MuiThemeProvider>
                </NavLink>
              </>
              :
              <div className="headerTextoResponsiveButton" style={{position: "relative"}}>
                <BotonVerde
                  texto={"AGREGAR EMPRESA"}
                  disabledBtn={props.disabledBtnAgregarEmpresa}
                  eventoClick={props.mostrarModalNewEmpresa} />
                {props.disabledBtnAgregarEmpresa && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
              </div>
            }
            </>
            :
            <></>
          }
          {
            (props.paginaPrincipal)?
              <>
              {
                (props.usuarioPerteneceEmpresa)?
                  <div style={{marginTop: '2px'}}>
                    <MuiThemeProvider theme={theme}>
                      <Typography variant="button" component="p" className="authHeaderNombreEmpresa">
                        {props.nombreEmpresa}
                      </Typography>
                    </MuiThemeProvider>
                  </div>
                :
                  <div style={{display: 'flex'}} className="headerTextoResponsive">
                    <div className="authHeaderEstiloTexto">
                      <MuiThemeProvider theme={theme}>
                        <Typography variant="button" component="p">
                          SI ERES PARTE DE UNA EMPRESA
                        </Typography>
                      </MuiThemeProvider>
                    </div>
                    <div style={{marginLeft: "10px", marginTop: "7px", position: "relative"}}>
                        <BotonVerde
                          texto={"¡ AGRÉGALO AQUÍ !"}
                          disabledBtn={props.disabledBtnAgregarEmpresa}
                          eventoClick={props.mostrarModalNewEmpresa}
                        />
                        {props.disabledBtnAgregarEmpresa && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                    </div>
                  </div>
              }
              </>
            :
            <></>
          }
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <ul className="authHeaderEstiloUl">
              <li className="authHeaderLi">
                <NavLink to="/home" className="authHeaderEstiloNav" activeClassName="authHeaderEstiloNavActive">
                  Inicio
                </NavLink>
              </li>
              <li className="authHeaderLi">
                <NavLink to="/solicitudes" className="authHeaderEstiloNav" activeClassName="authHeaderEstiloNavActive">
                  Solicitudes
                </NavLink>
              </li>
            </ul>
            <Button onClick={handleProfileMenuOpen} color="inherit">
              &nbsp;&nbsp;
              <Avatar style={{color: "#00A775", backgroundColor: "white"}}>
                {(usuario.usuarioNombre)? usuario.usuarioNombre.toUpperCase().substring(1,0) : 'TG'}
              </Avatar>
            </Button>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
