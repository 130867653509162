import React from 'react';
import './LinkPersonalizado.css';

import Typography from '@material-ui/core/Typography';

export default class LinkPersonalizado extends React.Component {
    render(){
        return(
            <Typography variant="body1" component="p">
                {this.props.texto}&nbsp;&nbsp;
                <a href={this.props.ruta} className="linkPersonalizadoTexto">
                    {this.props.textoLink}
                </a>
            </Typography>
        )
    }
}