import React from 'react';
import './CardTecnico.css';
import tgLetraColor from '../../assets/img/icons/tgLetraColor.svg';

import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core';

import Rating from '@material-ui/lab/Rating';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

class CardTecnico extends React.Component {
    
    render(){
        return(
            <Card className="cardTecnicoCard" style={{backgroundColor: "#F4F6F6"}}>
                <CardContent>
                    <div>
                        <img src={tgLetraColor} alt="Logo tecnigo" style={{width: "130px"}}/>
                    </div>
                    <Divider />
                    <Grid container style={{marginTop: "15px"}}>
                        <Grid item xs={12} md={4}>
                            <div className="cardTecnicoContenedorImagenTecnico">
                                 <CardMedia
                                    className="cardTecnicoImagenTecnico"
                                    image={this.props.imagen}/>
                                <Typography variant="body1" color="textSecondary"  component={"p"} style={{marginTop: "5px"}}>
                                    TecniGo
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div>
                                <div style={{marginTop: "5px"}}>
                                    <MuiThemeProvider theme={theme}>
                                        <Typography variant="h5" component="h2" className="cardTecnicoNombre">
                                            {this.props.nombre}
                                        </Typography>
                                    </MuiThemeProvider>
                                </div>
                                <div style={{marginTop: "10px"}}>
                                    {
                                        this.props.especialidades.map((e, index) => {
                                            return(
                                                <Typography key={index} variant="body1" component="p">
                                                    {e}
                                                </Typography>
                                            )
                                        })
                                    }
                                    <Typography variant="body1" component="p">
                                        {this.props.nroDocumento}
                                    </Typography>
                                    <Typography variant="body1" component="p">
                                        {this.props.celular}
                                    </Typography>
                                    <div style={{marginTop: "10px"}}>
                                        <Rating name="read-only"
                                                value={this.props.calificacion} 
                                                readOnly
                                                size="large" />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        )
    }
}

export default CardTecnico;