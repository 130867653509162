import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

class BotonVerdeOutlined extends React.Component {
    render(){
        return(
            <Button
                variant="outlined"
                type={this.props.type}
                className={this.props.classes.containedVerdeTecnigo}
                onClick={this.props.eventoClick}
                fullWidth
                disabled={this.props.disabledBtn}
                startIcon={this.props.icono}>
                {this.props.texto}
            </Button>
        )
    }
}
export default withStyles(theme => ({
    containedVerdeTecnigo: {
      color: '#00A775',
      borderColor: '#008e638e',
      border: '1px solid',
      "&:hover": {
        borderColor: '#00A775',
        "@media (hover: none)": {
          borderColor: '#00A775'
        }
      }
    }
  }))(BotonVerdeOutlined)