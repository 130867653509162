import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

class BotonVerde extends React.Component {
    render(){
        return(
            <Button
                variant="contained"
                type={this.props.type}
                className={this.props.classes.containedVerdeTecnigo}
                onClick={this.props.eventoClick}
                fullWidth
                disabled={this.props.disabledBtn}
                startIcon={this.props.icono}>
                {this.props.texto}
            </Button>
        )
    }
}
export default withStyles(theme => ({
    containedVerdeTecnigo: {
      color: theme.palette.getContrastText('#00A775'),
      backgroundColor: '#00A775',
      "&:hover": {
        backgroundColor: '#008e63',
        "@media (hover: none)": {
          backgroundColor: '#00A775'
        }
      }
    }
  }))(BotonVerde)