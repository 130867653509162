import React, { Component } from 'react';

import TecniGoCrearSolicitud from '../../assets/img/tecnigo_crear_solicitud.png';
import TecnigoPagoSolicitud from '../../assets/img/tecnigo_pago_solicitud.png';
import TecnigoRegistrate from '../../assets/img/tecnigo_registrate.png';
import TecnigoPromocional from '../../assets/img/tecnigo_promocional.png';

import Video from './Video';
import CardVideo from './CardVideo';

class CardListaVideo extends Component {

    constructor(props){
        super(props)
        this.state={
            psvid: "",
            idvid: "",
            psprop:"",
            listaVideos:[
                { nombre: 'TecniGo', descripcion: 'TecniGo',srcvi: 'https://www.youtube.com/embed/_7WfAZ5fYoI', id:1,video_id: '_7WfAZ5fYoI', imagen: TecnigoPromocional },
                { nombre: 'TecniGo Web', descripcion: 'TecniGo',srcvi: 'https://www.youtube.com/embed/Cmo2H5jXeLc', id:2,video_id: 'Cmo2H5jXeLc', imagen: TecnigoRegistrate },
                { nombre: 'TecniGo App', descripcion: 'TecniGo',srcvi: 'https://www.youtube.com/embed/lTeRcW79-Zg' ,id: 3,video_id:'lTeRcW79-Zg', imagen: TecniGoCrearSolicitud },
                { nombre: 'Pide un TecniGo', descripcion: 'TecniGo',srcvi: 'https://www.youtube.com/embed/0p_FfLPXoms', id:4,video_id: '0p_FfLPXoms', imagen: TecnigoPagoSolicitud }
            ]
        }
    }
     /**
     * Mostrar video
     */
    mivideo = (vin) =>  {
        var vilo = vin.srcvi;
        this.setState({
            psvid: vilo,
            idvid: vin.video_id
        });
    };

    render() {
        
        return (
            <div>
                <div style={{marginBottom: "30px"}}>
                    <div className="contenedorVideos">
                        <CardVideo  imagen={TecnigoPromocional}
                                    tituloImagen={"TecniGo"}
                                    titulo={"TecniGo"}
                                    descripcion={"TecniGo"}
                                    objVideo={this.state.listaVideos[0]}
                                    mivideo={this.mivideo}/>

                        <CardVideo  imagen={TecnigoRegistrate}
                                    tituloImagen={"TecniGo Web"}
                                    titulo={"TecniGo Web"}
                                    descripcion={"Plataforma web"}
                                    objVideo={this.state.listaVideos[1]}
                                    mivideo={this.mivideo}/>

                        <CardVideo  imagen={TecniGoCrearSolicitud}
                                    tituloImagen={"TecniGo App"}
                                    titulo={"TecniGo App"}
                                    descripcion={"Aplicación android"}
                                    objVideo={this.state.listaVideos[2]}
                                    mivideo={this.mivideo}/>

                        <CardVideo  imagen={TecnigoPagoSolicitud}
                                    tituloImagen={"Pide un TecniGo"}
                                    titulo={"Pide un TecniGo"}
                                    descripcion={"TecniGo confianza a un click de distancia"}
                                    objVideo={this.state.listaVideos[3]}
                                    mivideo={this.mivideo}/>
                    </div>
                </div>
                <div>
                    <Video 
                        senthis={this.state.psprop}
                        vidsend={this.state.psvid}
                        idvideo={this.state.idvid}
                    />
                </div>
            </div>
        )
    }

}

export default CardListaVideo;