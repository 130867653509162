import React from 'react';

import Footer from '../footer/Footer';
import Header from '../header/Header';

export default class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div>
        <header>
          <Header nombreEmpresa={this.props.nombreEmpresa}
                  usuarioPerteneceEmpresa={this.props.usuarioPerteneceEmpresa}
                  disabledBtnAgregarEmpresa={this.props.disabledBtnAgregarEmpresa}
                  mostrarModalNewEmpresa={this.props.mostrarModalNewEmpresa}
                  paginaPrincipal={this.props.paginaPrincipal}/>
        </header>
        <main>{children}</main>
        <footer>
          <Footer />
        </footer>
      </div>
    );
  }
}