import React from "react";
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EmojiFlagsIcon from '@material-ui/icons/EmojiFlags';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import SettingsOverscanOutlinedIcon from '@material-ui/icons/SettingsOverscanOutlined';
import AutocompleteInput from './AutocompleteInput';

export default class FormNewDireccion extends React.Component {

    setReferencia = (valor) => {
        this.props.setReferencia(valor.target.value)
    }
    setCalle = (valor) => {
        this.props.setCalle(valor.target.value);
    }
    /**
     * Mostrar linear progress
     */
    mostrarLinearProgress = () => {
        this.props.mostrarLinearProgress();
    }
    /**
     * Set latitud
     */
    setLatitud = (lat) => {
        this.props.setLatitud(lat);
    }
    /**
     * Set longitud
     */
    setLongitud = (lon) => {
        this.props.setLongitud(lon);
    }
    /**
     * Set Direccion
     */
    setDireccion = (dir) => {
        this.props.setDireccion(dir);
    }
    /**
     * Set Distrito
     */
    setDistrito = (dis) => {
        this.props.setDistrito(dis);
    }
    /***
     * Set Provincia
     */
    setProvincia = (prov) => {
        this.props.setProvincia(prov);
    }
    /**
     * Set Ciudad
     */
    setCiudad = (ciu) => {
        this.props.setCiudad(ciu);
    }
    /**
     * Set Pais
     */
    setPais = (pais) => {
        this.props.setPais(pais);
    }
    /**
     * Ocultar linear progress
     */
    ocultarLinearProgress = () => {
        this.props.ocultarLinearProgress();
    }
    /**
     * Habilitar el boton direccion
     */
    habilitarBotonAgregarDireccion = () => {
        this.props.habilitarBotonAgregarDireccion();
    }
    /**
     * Deshabilitar el boton direccion
     */
    deshabilitarBotonAgregarDireccion = () => {
        this.props.deshabilitarBotonAgregarDireccion();
    }
    /**
     * Clear error message
     */
    clearErrorMessage = () => {
        this.props.clearErrorMessage();
    }
    
    render(){
        return (
            <div>
                <div>
                    <AutocompleteInput 
                        mostrarLinearProgress = {this.mostrarLinearProgress}
                        setLatitud = {this.setLatitud}
                        setLongitud = {this.setLongitud}
                        setDireccion = {this.setDireccion}
                        setDistrito = {this.setDistrito}
                        setProvincia = {this.setProvincia}
                        setCiudad = {this.setCiudad}
                        setPais = {this.setPais}
                        ocultarLinearProgress = {this.ocultarLinearProgress}
                        habilitarBotonAgregarDireccion = {this.habilitarBotonAgregarDireccion}
                        deshabilitarBotonAgregarDireccion = {this.deshabilitarBotonAgregarDireccion}
                        clearErrorMessage = {this.clearErrorMessage}
                    />
                </div>
                <div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                        <Grid container>
                            <Grid>
                                <EmojiFlagsIcon style={{marginTop: "5px", fontSize: 22}}/>
                            </Grid>
                            <Grid>
                                <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                    Dirección seleccionada
                                </Typography>
                            </Grid>
                        </Grid>
                        <TextField
                            style={{width: '100%'}}
                            id="input-with-icon-textfield-direccion"
                            size="small"
                            variant="outlined"
                            multiline
                            rowsMax={2}
                            rows={2}
                            autoComplete="none"
                            value={this.props.direccion}
                            disabled
                            InputProps={{
                                style: {
                                    color: '#3B3945'
                                }
                            }}/>
                    </div>
                    {
                        (this.props.errorProvinciaCiudadPais)?
                            <Typography variant="subtitle2" component="p" style={{color: "#f44336"}}>
                                Buscar por calle, ciudad, distrito...
                            </Typography>
                        :<></>
                    }
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                        <Grid container>
                            <Grid>
                                <SettingsOverscanOutlinedIcon style={{marginTop: "5px", fontSize: 22}}/>
                            </Grid>
                            <Grid>
                                <Grid container>
                                    <Grid>
                                        <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                            Calle / Av
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="subtitle2" component="p" style={{margin: "5px"}}>
                                            (Opcional)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <TextField
                            style={{width: '100%'}}
                            id="input-with-icon-textfield-calle"
                            placeholder=""
                            size="small"
                            variant="outlined"
                            value={this.props.calle}
                            onChange={this.setCalle}
                            InputProps={{
                                style: {
                                    backgroundColor: '#f2f2f2'
                                }
                            }}/>
                    </div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                        <Grid container>
                            <Grid>
                                <HomeOutlinedIcon style={{marginTop: "5px", fontSize: 22}}/>
                            </Grid>
                            <Grid>
                                <Grid container>
                                    <Grid>
                                        <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                            Referencia
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="subtitle2" component="p" style={{margin: "5px"}}>
                                            (Opcional)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <TextField
                            style={{width: '100%'}}
                            id="input-with-icon-textfield-referencia"
                            placeholder=""
                            size="small"
                            variant="outlined"
                            value={this.props.referencia}
                            onChange={this.setReferencia}
                            InputProps={{
                                style: {
                                    backgroundColor: '#f2f2f2'
                                }
                            }}/>
                    </div>
                    
                </div>
    
            </div>
        );
    }
}