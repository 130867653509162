import React, { Component } from 'react';
import { withSnackbar } from 'notistack';
import axios from '../utils/axios';
import jwt from 'jwt-decode';
import './Home.css';

import { responsiveFontSizes, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';


import activarLicencia from '../assets/img/icons/servicios/activarLicencia.svg';
import instalarProgramas from '../assets/img/icons/servicios/instalarProgramas.svg';
import internetLento from '../assets/img/icons/servicios/internetLento.svg';
import mantenimientoPc from '../assets/img/icons/servicios/mantenimientoPc.svg';
import maquinaLenta from '../assets/img/icons/servicios/maquinaLenta.svg';
import pcNoEnciende from '../assets/img/icons/servicios/pcNoEnciende.svg';
import probableVirus from '../assets/img/icons/servicios/probableVirus.svg';
import problemasConCorreo from '../assets/img/icons/servicios/problemasConCorreo.svg';
import problemasConImpresora from '../assets/img/icons/servicios/problemasConImpresora.svg';
import recuperarInformacion from '../assets/img/icons/servicios/recuperarInformacion.svg';
import tgLetraBlanco from '../assets/img/icons/tgLetraBlanco.svg';

import ModalNewSolicitud from '../components/home/ModalNewSolicitud';
import ModalNewEmpresa from '../components/home/ModalNewEmpresa';
import CardListaVideo from '../components/home/CardListaVideo';
import BotonVerdeLink from '../components/general/BotonVerdeLink';
import Layout from '../components/layout/Layout';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

/**
 * Imagenes para los servicios limite 20 servicios
 */
const imagenesServicios = [maquinaLenta, recuperarInformacion, activarLicencia, problemasConImpresora, probableVirus, problemasConCorreo, internetLento, pcNoEnciende, instalarProgramas, mantenimientoPc];

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      usuarioPerteneceEmpresa: false,
      empresaDelUsuario: {},
      empresaBloqueado: false,
      openModalNewSolicitud: false,
      openModalNewEmpresa: false,
      listaServicios: [],
      listaOpciones: [],
      nombreProblema: "",
      precioServicio: 0,
      abrirProgreso: false,
      disabledBtnAgregarEmpresa: false,
      mensajeConfirmacionSolicitud: false
    }
}

componentDidMount(){
  /**
   * Verificar token
   */
  if(this.invalidToken()){
    this.logoutByTokenExpired();
  }

  // Abrir vista progreso
  this.abrirVistaProgreso(true);

  const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
  /**
   * Reiniciar mensaje de confirmacion
   */
  this.setState({mensajeConfirmacionSolicitud: false});
  /***
   * Obtener las empresas del usuario
   */
  this.getEmpresaUsuario();
  /**
   * Obtener la empresa bloqueado
   */
  this.getEmpresaBloqueado();
  /**
   * Verificar si ya se ha obtenido la lista de servicios
   */
  if(localStorage.getItem("lista_servicios") === undefined || localStorage.getItem("lista_servicios") === null){
    /**
     * Obtener la lista de servicios
     */
    axios.get('usuario/servicio/lista/', { headers: { Authorization: authString } })
      .then(response =>{
        /**
         * Guardar la lista de servicios y sus detalles
         */
        localStorage.setItem('lista_servicios', JSON.stringify(response.data));
        this.setState({
          listaServicios: response.data
        });
        // Cerrar vista progreso
        this.abrirVistaProgreso(false);
      })
      .catch(error => {
        // Cerrar vista progreso
        this.abrirVistaProgreso(false);
      });  
  }else{
    this.setState({listaServicios: []});
    // Cerrar vista progreso
    this.abrirVistaProgreso(false);
    this.setState({listaServicios: JSON.parse(localStorage.getItem("lista_servicios"))})
  }
 
}
/**
 * Obtener la empresa del usuario
 */
getEmpresaUsuario = () => {
  /**
   * Verificar token
   */
  if(this.invalidToken()){
    this.logoutByTokenExpired();
  }

  const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
  const dataUser = localStorage.getItem('data_user');
  const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
  /**
   * Verificar si ya se ha obtenido la empresa
   */
  if(localStorage.getItem("datos_empresa") === undefined || localStorage.getItem("datos_empresa") === null){
      /**
     * Obtener la empresa del usaurio
     */
    axios.get(`usuario/empresa/por/usuario/${usuarioCod}`, { headers: { Authorization: authString } })
    .then(response =>{
        /**
         * Verificar si tiene empresa
         */
        if(response.data.cod === null || response.data.cod === undefined){
          /**
           * Usuario no tiene empresa
           */
          this.setState({usuarioPerteneceEmpresa: false});
        }else{
          /**
           * Usuario tiene empresa
           */
            /**
           * Guardar los datos de la empresa
           */
          localStorage.setItem('datos_empresa', JSON.stringify(response.data));
          this.setState({
            usuarioPerteneceEmpresa: true,
            empresaDelUsuario: response.data,
            empresaBloqueado: response.data.bloqueado
          });
        }
    }).catch(error => {
      console.log(error);
    });
  }else{
    this.setState({empresaDelUsuario: {}, usuarioPerteneceEmpresa: true});
    this.setState({empresaDelUsuario: JSON.parse(localStorage.getItem("datos_empresa"))});
  }
}
/**
 * Mostrar modal new solicitud
 */
mostrarModalNewSolicitud = (servicio) => {
  
  // Verificar token
  if(this.invalidToken()){
    this.logoutByTokenExpired();
  }
  // Abrir vista progreso
  this.abrirVistaProgreso(true);
  
  // Verificar si la empreas esta bloqueado
  const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
  const dataUser = localStorage.getItem('data_user');
  const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
  
  // Obtener la empresa del usaurio
  axios.get(`usuario/empresa/por/usuario/${usuarioCod}`, { headers: { Authorization: authString } })
  .then(res =>{
      // Cerrar vista progreso
      this.abrirVistaProgreso(false);
      
      // Verificar si tiene empresa
      if(res.data.cod === null || res.data.cod === undefined){
        // El usuario no tiene empresa
        // Limpiar las opciones
        this.setState({
          listaOpciones: [],
          nombreProblema: "",
          precioServicio: 0
        });
        this.setState({
          listaOpciones: servicio.servicioDetalle,
          nombreProblema: servicio.nombre,
          precioServicio: servicio.precio,
          openModalNewSolicitud: true
        });
      }else{
        // EL usuario tiene empresa
        // Set estado bloqueado
        this.setState({empresaBloqueado: res.data.bloqueado});
        // Verificar si esta bloqueado
        if(res.data.bloqueado){
          // Empresa bloqueado
          this.props.enqueueSnackbar( 'Pagos no regularizados, por favor comuníquese al', {
                                      variant: 'warning',
                                      action: this.action,
                                      autoHideDuration: 30000,
                                      anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }
                                    });
        }else{
          /**
           * Empresa no esta bloqueado
           */
          /**
           * Limpiar las opciones
           */
          this.setState({
            listaOpciones: [],
            nombreProblema: "",
            precioServicio: 0
          });
          this.setState({
            listaOpciones: servicio.servicioDetalle,
            nombreProblema: servicio.nombre,
            precioServicio: servicio.precio,
            openModalNewSolicitud: true
          });
        }
      }
  }).catch(error => {
    console.log(error);
    // Cerrar vista progreso
    this.abrirVistaProgreso(false);
  });
}
/**
 * Ocultar modal new solicitud
 */
ocultarModalNewSolicitud = () => {
  this.setState({openModalNewSolicitud: false});
}
/***
 * Mostrar modal new empresa
 */
mostrarModalNewEmpresa = () => {
  /**
   * Verificar token
   */
  if(this.invalidToken()){
    this.logoutByTokenExpired();
  }
  /**
   * Iniciar el cargando
   */
  this.setState({disabledBtnAgregarEmpresa: true});
  /**
   * Verificar si el usuario ya pertenece a una empresa
   */
  const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
  const dataUser = localStorage.getItem('data_user');
  const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
  /**
   * Obtener la empresa del usaurio
   */
  axios.get(`usuario/empresa/por/usuario/${usuarioCod}`, { headers: { Authorization: authString } })
    .then(response =>{
        /**
         * Verificar si tiene empresa
         */
        if(response.data.cod === null || response.data.cod === undefined){
          /**
           * Usuario no tiene empresa
           */
          this.setState({
            usuarioPerteneceEmpresa: false,
            openModalNewEmpresa: true,
            disabledBtnAgregarEmpresa: false
            });
        }else{
          /**
           * Usuario tiene empresa
           */
          this.setState({
            usuarioPerteneceEmpresa: true,
            empresaDelUsuario: response.data,
            disabledBtnAgregarEmpresa: false,
            empresaBloqueado: response.data.bloqueado
          });
           /**
           * Guardar los datos de la empresa
           */
          localStorage.setItem('datos_empresa', JSON.stringify(response.data));
        }
    }).catch(error => {
      this.setState({disabledBtnAgregarEmpresa: false});
    });
}
/***
 * Ocultar modal new empresa
 */
ocultarModalNewEmpresa = () => {
  this.setState({openModalNewEmpresa: false});
}
/**
 * Mostrar link de confirmacion de la solicitud
 */
mostrarLinkConfirmacionSolicitud = () => {
  this.setState({mensajeConfirmacionSolicitud: true});
}
/**
 * Obtener si la empresa esta bloqueado
 */
getEmpresaBloqueado = () => {
  const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
  const dataUser = localStorage.getItem('data_user');
  const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
  /**
   * Obtener la empresa del usaurio
   */
  axios.get(`usuario/empresa/por/usuario/${usuarioCod}`, { headers: { Authorization: authString } })
  .then(res =>{
      /**
       * Verificar si tiene empresa
       */
      if(res.data.cod !== null && res.data.cod !== undefined){
        /**
         * Si tiene empresa
         */
        this.setState({empresaBloqueado: res.data.bloqueado});
        if(res.data.bloqueado){
          this.props.enqueueSnackbar( 'Pagos no regularizados, por favor comuníquese al', {
                                      variant: 'warning',
                                      action: this.action,
                                      autoHideDuration: 30000,
                                      anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'center',
                                      }
                                    });
        }
      }
  }).catch(error => {
    console.log(error);
  });
}
/**
 * Accion del Snacbar
 */
action = key => (
  <div style={{display: 'flex', justifyContent: 'space-between'}}>
    <div style={{marginTop: '15px', marginRight: '20px', marginLeft: '-5px'}}>
      <a  style={{color: '#4315A8', letterSpacing: '.5px', cursor: 'pointer', textDecoration: 'underline', textUnderlinePosition: 'under'}}
          href="https://api.whatsapp.com/send/?phone=+51958310833" target="_blank" rel="noopener noreferrer">
          958310833
      </a>
    </div>
    <div>
      <IconButton onClick={() => { this.props.closeSnackbar(key) }}>
        <CloseIcon />
      </IconButton>
    </div>
  </div>
);

  /***
   * Abrir progreso
   */
  abrirVistaProgreso = (abrir) => {
    this.setState({abrirProgreso: abrir});
  }
/**
 * ******************************
 * Verificar validacion de token
 * ******************************
 */
invalidToken = () => {
  const tokenId = localStorage.getItem("tokenuser");
  const decodeToken = jwt(tokenId);
  // Expiracion del token en segundos
  const expTokenSegundos = decodeToken.exp;
  // Fecha actual en segundos
  const todaySegundos = Date.now() / 1000;
  // 1590602256.671 > 1590601904
  return todaySegundos > expTokenSegundos;
}
/**
 * logout by token expired
 */
logoutByTokenExpired = () => {
  localStorage.removeItem('tokenuser');
  localStorage.removeItem('data_user');
  localStorage.removeItem('lista_servicios');
  localStorage.removeItem('datos_empresa');
  this.props.history.push("/");
}

  render() {

    return (
      <Layout nombreEmpresa={this.state.empresaDelUsuario.nombreComercial}
              usuarioPerteneceEmpresa={this.state.usuarioPerteneceEmpresa}
              disabledBtnAgregarEmpresa={this.state.disabledBtnAgregarEmpresa}
              mostrarModalNewEmpresa={this.mostrarModalNewEmpresa}
              paginaPrincipal={true}>
        <Backdrop style={{zIndex: '1', color:'white'}} open={this.state.abrirProgreso}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="background-color-dradiente-home">
          <Container>
            <div style={{paddingTop:"15px"}}>
            </div>
            <div className="homeContenedorEstiloLogoTecnigo">
              <img src={tgLetraBlanco} alt="Logo TecniGo" className="homeEstiloLogoTecnigo"/>
            </div>
            <div style={{textAlign: 'center'}}>
              <h2 className="homeTextoH2Titulo">Confianza a un click de distancia</h2>
            </div>
            {
              (this.state.empresaBloqueado)?
              <div style={{textAlign: 'center'}}>
                <h2 className="homeTextoH2Titulo" style={{color: '#F3CE13'}}>Pagos no regularizados</h2>
                <p className="homeTextoP">Para poder atenderlo, por favor comuníquese al&nbsp;&nbsp;
                  <a  style={{color: '#13F30B', letterSpacing: '.5px', cursor: 'pointer', textDecoration: 'underline', textUnderlinePosition: 'under'}}
                      href="https://api.whatsapp.com/send/?phone=+51958310833" target="_blank" rel="noopener noreferrer">
                      958310833
                  </a>
                </p>
              </div>
              :
              <></>
            }
            {
              (this.state.mensajeConfirmacionSolicitud)?
                <div style={{textAlign: 'center'}}>
                  <BotonVerdeLink ruta={"/solicitudes"} nombre={"Ver la solicitud creada"}/>
                </div>
              :<></>
            }
            <div className="homeContenedorGeneralServicios">
              <div className="homeContenedorServicios">
                {
                  this.state.listaServicios.map((servicio, i) => {
                    return(
                      <div key={i} className="homeContenedorGeneralUnServicio">
                        <div className="homeContenedorUnServicio" onClick={() => this.mostrarModalNewSolicitud(servicio)}>
                          <img src={imagenesServicios[i]} className="homeCirculo" alt="Servicios TecniGo"/>
                          <MuiThemeProvider theme={theme}>
                            <Typography variant="body1" component="p" style={{marginTop: "5px", color: "#fff"}}>
                              {servicio.nombre}
                            </Typography>
                          </MuiThemeProvider>
                        </div>
                      </div>
                      )
                    })
                  }
              </div>
            </div>
            
          </Container>
        </div>
        {
          /**
           * Lista de videos
           */
        }
        <Container>
          <div style={{marginTop:"30px", marginBottom:"30px"}}>
            <MuiThemeProvider theme={theme}>
                <Typography variant="h4" component="h2">
                    Videos Sugeridos
                </Typography>
            </MuiThemeProvider>
          </div>
        </Container>
        <div style={{backgroundColor: "#F4F6F6", paddingBottom: "50px", paddingTop: "30px"}}>
          <Container>
            <CardListaVideo />
          </Container>
        </div>
        {
          /**
           * Ventanas modales
           */
        }

        <ModalNewEmpresa
          openModalNewEmpresa = {this.state.openModalNewEmpresa}
          ocultarModalNewEmpresa = {this.ocultarModalNewEmpresa}
          getEmpresaUsuario = {this.getEmpresaUsuario} />

        <ModalNewSolicitud 
          openModalNewSolicitud = {this.state.openModalNewSolicitud}
          ocultarModalNewSolicitud = {this.ocultarModalNewSolicitud}
          mostrarLinkConfirmacionSolicitud = {this.mostrarLinkConfirmacionSolicitud}
          getEmpresaBloqueado = {this.getEmpresaBloqueado}
          listaOpciones = {this.state.listaOpciones}
          nombreProblema = {this.state.nombreProblema}
          precioServicio = {this.state.precioServicio} />

      </Layout>
    )
  }
}

export default withSnackbar(Home);