import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center'
    }
  }));

const Video = (props) => {
    const classes = useStyles();
    return( 
        <div>
         {
            props.vidsend === "" ?
            (
              <div className={classes.root}>
                <iframe className="iframe-video-home"
                        title="uno"
                        src={"https://www.youtube.com/embed/_7WfAZ5fYoI"} 
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
              </div>
            )
            :
            (
              <div className={classes.root}>
                <iframe className="iframe-video-home"
                        title="dos" 
                        src={props.vidsend}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>
              </div>
            )
          }
    </div>
    )
}

export default Video;