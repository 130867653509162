import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default class TableSolicitudesRealizadas extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            rowsPerPage: 5,
            page: 0
        }
        
    }

    /**
     * Evento cambiar pagina
     */
    handleChangePage = (event, newPage) => {
        this.setState({page: newPage});
    };

    render(){

        const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.listaSolicitudes.length - this.state.page * this.state.rowsPerPage);

        return(
           <div>
                <div style={{marginTop: '30px'}}>
                    <TableContainer component={Paper}>
                        <Table size="medium">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Solicitud</TableCell>
                                    <TableCell align="left">Descripción</TableCell>
                                    <TableCell align="left">Solicitado por</TableCell>
                                    <TableCell align="center">Fecha de atención</TableCell>
                                    <TableCell align="center">Fecha Finalizado</TableCell>
                                    <TableCell align="right">Tiempo de trabajo</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                 {(this.state.rowsPerPage > 0
                                        ? this.props.listaSolicitudes.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        : this.props.listaSolicitudes
                                    ).map((solicitud) => (
                                        <TableRow key={solicitud.solicitudCod} hover>
                                            <TableCell style={{ width: '6%' }} align="left">
                                                {solicitud.solicitudCod}
                                            </TableCell>
                                            <TableCell style={{ width: '25%' }} align="left">
                                                {solicitud.solicitudDescripcion}
                                            </TableCell>
                                            <TableCell style={{ width: '24%' }} align="left">
                                                {solicitud.usuarioNombre}
                                            </TableCell>
                                            <TableCell style={{ width: '15%' }} align="center">
                                                {solicitud.solicitudFechaAtencion}
                                            </TableCell>
                                            <TableCell style={{ width: '15%' }} align="center">
                                                {solicitud.solicitudFechaFinalizado}
                                            </TableCell>
                                            <TableCell style={{ width: '15%' }} align="right">
                                                {solicitud.solicitudTiempoTrabajo}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 50 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5]}
                                        colSpan={6}
                                        count={this.props.listaSolicitudes.length}
                                        rowsPerPage={this.state.rowsPerPage}
                                        page={this.state.page}
                                        onChangePage={this.handleChangePage} />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </div>
                <div style={{marginTop: '15px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3} style={{marginTop: '-20px'}}>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{marginTop: '-20px'}}>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} style={{marginTop: '-20px'}}>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Card>
                                <div style={{display: 'flex', justifyContent: 'space-between', padding: '20px'}}>
                                    <div>
                                        <MuiThemeProvider theme={theme}>
                                            <Typography variant="body1" component="p">
                                                Tiempo total de trabajo
                                            </Typography>
                                        </MuiThemeProvider>
                                    </div>
                                    <div>
                                        <MuiThemeProvider theme={theme}>
                                            <Typography variant="body1" component="p" style={{fontWeight: 'bold'}}>
                                                {this.props.totalMinutos}
                                            </Typography>
                                        </MuiThemeProvider>
                                    </div>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </div>
           </div>
        );
    }
}