import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PlayStore from '../../assets/img/icons/playStore.png';
import tgLetraBlanco from '../../assets/img/icons/tgLetraBlanco.svg';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

export default function Footer() {

  return (
      <Grid container className="footerGridMainContainer">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className="footerGridSecondContainer">
          <Grid item xs={12} sm={8}>
            <div className="footerDivImage">
              <img src={tgLetraBlanco} className="footerLogoImageSize" alt="Logo Tecnigo"/>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className="footerDivContenedorIcons">
              <IconButton aria-label="Facebook Tecnigo" href="https://www.facebook.com/tecnigo/" target="_blank">
                <FacebookIcon fontSize="large" className="footerColorBlanco"/>
              </IconButton>
              <IconButton aria-label="Youtube Tecnigo" href="https://www.youtube.com/channel/UCUqguS6iMWLJhLv0-WNjJkA" target="_blank">
                <YouTubeIcon fontSize="large" className="footerColorBlanco"/>
              </IconButton>
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className="footerGridSecondBottomContainer">
          <Grid item xs={12} sm={8}>
            <div className="footerDivImage">
              <Typography variant="body2" className="footerColorBlanco">
                {'Copyright © '}
                <Link to="/home" className="footerColorBlancoLink">
                  www.tecnigo.app
                </Link>{' '}
                {new Date().getFullYear()}
                {' | '}
                <Link to="/home" className="footerColorBlancoLink">
                  Términos y Condiciones
                </Link>{' '}
                {' | '}
                <Link to="/home" className="footerColorBlancoLink">
                  Términos para Profesionales
                </Link>{' '}
                {' | '}
                <Link to="/home" className="footerColorBlancoLink">
                  Privacidad
                </Link>{' '}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <a href="https://play.google.com/store/apps/details?id=com.tecnigo.tecnigo" target="_blank"  rel="noopener noreferrer">
              <div className="footerDivContainerImage">
                <img src={PlayStore} className="footerLogoGooglePlayImageSize" alt="Aplicación TecniGo"/>
              </div>
            </a>
          </Grid>
        </Grid>
      </Grid>
  );
}