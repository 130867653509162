import React from 'react';
import axios from '../../utils/axios';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

export default class DetailEmpresa extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            nombre: '',
            ruc: ''
        }
    }
    componentDidMount(){
        const dataUser = localStorage.getItem('data_user');
        const usuarioJSON = JSON.parse(dataUser).data;
        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        axios.get(`usuario/empresa/por/usuario/${usuarioJSON.usuarioCod}`, { headers: { Authorization: authString } })
        .then(res =>{
            this.setState({ nombre: (res.data.nombreComercial === null)? '': res.data.nombreComercial,
                            ruc: (res.data.ruc === null)? '': res.data.ruc});
        })
        .catch(error => {
            console.log(error);
        });
    }

    render(){
        return(
            <div style={{marginTop: '15px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={8} md={6}>
                        <div>
                            <Typography variant="body1" component="p">
                                Nombre
                            </Typography>
                            <TextField  name="nombre"
                                        variant="outlined"
                                        size="small"
                                        multiline
                                        rows={2}
                                        value={this.state.nombre}
                                        fullWidth
                                        disabled
                                        InputProps={{
                                            style: {
                                                color: '#3B3945'
                                            }
                                        }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <div>
                            <Typography variant="body1" component="p">
                                RUC
                            </Typography>
                            <TextField  name="ruc"
                                        variant="outlined"
                                        size="small"
                                        disabled
                                        value={this.state.ruc}
                                        fullWidth
                                        InputProps={{
                                            style: {
                                                color: '#3B3945'
                                            }
                                        }}/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}