import React from 'react';
import axios from '../../utils/axios';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import BotonVerdeOutlined from '../general/BotonVerdeOutlined';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class FormSolicitudesRealizadas extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            busquedaNombre: 'esteMes',
            formFechaDesde: this.getFechaInicioMes(),
            formFechaHasta: this.getFechaFinalMes(),
            formFechaDesdeError: false,
            formFechaDesdeErrorTexto: '',
            formFechaHastaError: false,
            formFechaHastaErrorTexto: '',
            fechaHaceUnAnio: this.getFechaDeHaceUnAnio(),
            disabledBtnGenerarReporte: false,
            openMessage: false,
            tipoMessage: "success",
            textoMessage: ""
        }
    }

    componentDidMount() {
        this.generarReporte();
    }

    /**
     * Generar el reporte
     */
    generarReporte = () => {
        let validado = true;
        const fechaDesde = this.state.formFechaDesde;
        const fechaHasta = this.state.formFechaHasta;
        if(this.campoEstaVacio(fechaDesde)){
            validado = false;
            this.setState({ formFechaDesdeError: true, formFechaDesdeErrorTexto: 'Seleccionar una fecha'});
        }
        if(this.campoEstaVacio(fechaHasta)){
            validado = false;
            this.setState({ formFechaHastaError: true, formFechaHastaErrorTexto: 'Seleccionar una fecha'});
        }
        if(validado){
            // Deshabilitar boton
            this.deshabilitarBtnGenerarReporte(true);

            // Set fechas
            this.props.setFechaDesde(fechaDesde);
            this.props.setFechaHasta(fechaHasta);

            // generar reporte
            const dataUser = localStorage.getItem('data_user');
            const usuarioJSON = JSON.parse(dataUser).data;
            const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
            axios.get(`usuario/solicitudes/atentidos/por/usuario/${usuarioJSON.usuarioCod}/desde/${fechaDesde}/hasta/${fechaHasta}`, { headers: { Authorization: authString } })
            .then(res =>{
                let lista = res.data;
                // Set lista solicitudes
                this.props.setListaSolicitudes(lista);
                // Get total minutos
                let minutos = lista.map(s => {
                    return s.solicitudTiempoMinutos;
                });
                let totalMinutos = minutos.reduce((accumulator, currentValue) => {
                    return accumulator + currentValue;
                }, 0);
                this.props.setTotalMinutos(this.minutosAHoraMinutoSegundo(totalMinutos));

                // Deshabilitar boton
                this.deshabilitarBtnGenerarReporte(false);
            })
            .catch(error => {
                // Deshabilitar boton
                this.deshabilitarBtnGenerarReporte(false);
                // Manejo de errores
                if(error.response){
                    const httpErrorResponse = error.response.data;
                    switch(httpErrorResponse.status){
                        case 400:
                            if (httpErrorResponse !== '' && httpErrorResponse.title) {
                                this.showMessage("error", httpErrorResponse.title);
                            } else {
                                this.showMessage("error", "Hubo un problema al generar el reporte");
                            }
                            break;
                        default:
                            this.showMessage("error", "Hubo un problema al generar el reporte");
                            break;
                    }
                }else{
                    this.showMessage("warning", "No tiene conexión a internet");
                }
            });
        }
    }

    /**
     * Fecha de hace un anio
     */
    getFechaDeHaceUnAnio = () => {
        let unAnioEnMilisegundos = 1000 * 60 * 60 * 24 * 365;
        let hoy = new Date();
        let resta = hoy.getTime() - unAnioEnMilisegundos;
        let fechaDeHaceUnAnio = new Date(resta);
        return (fechaDeHaceUnAnio.getFullYear() + '-' + this.agregarCeroANumeroDeUnDigito(fechaDeHaceUnAnio.getMonth() + 1) + '-' + this.agregarCeroANumeroDeUnDigito(fechaDeHaceUnAnio.getDate()));
    }
    /**
     * Minutos a hora minutos y segundos
     */
    minutosAHoraMinutoSegundo = (minutos) => {
        let resultado = minutos / 60;
        let horas = parseInt(resultado, 10);
        let horaString = (horas < 10)? "0" + horas : horas.toString();
        let totalMinutos = minutos % 60;
        let minutoString = (totalMinutos < 10)? "0" + totalMinutos : totalMinutos.toString();
        return horaString + ":" + minutoString + ":00";
    }

    /**
     * OnChange busqueda nombre
     */
     onChangeBusquedaNombre = (event) => {
        this.setState({ formFechaDesdeError: false, 
                        formFechaDesdeErrorTexto: "",
                        formFechaHastaError: false,
                        formFechaHastaErrorTexto: ""});

        const nombreSeleccionado = event.target.value;
        if(nombreSeleccionado === 'esteMes'){
            this.setState({formFechaDesde: this.getFechaInicioMes(), formFechaHasta: this.getFechaFinalMes()});
        }else if(nombreSeleccionado === 'esteAnio') {
            this.setState({formFechaDesde: this.getFechaInicioAnio(), formFechaHasta: this.getFechaFinalAnio()});
        }
        this.setNombreBusqueda(nombreSeleccionado);
    }
    
    /**
     * Get fecha inicio mes
     */
    getFechaInicioMes = () => {
        let date = new Date();
        let dateInicioMes = new Date(date.getFullYear(), date.getMonth(), 1);
        return (dateInicioMes.getFullYear() + '-' + this.agregarCeroANumeroDeUnDigito(dateInicioMes.getMonth() + 1) + '-' + this.agregarCeroANumeroDeUnDigito(dateInicioMes.getDate()));
    }
    
    /**
     * Get fecha final mes
     */
    getFechaFinalMes = () => {
        let date = new Date();
        let dateFinalMes = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return (dateFinalMes.getFullYear() + '-' + this.agregarCeroANumeroDeUnDigito(dateFinalMes.getMonth() + 1) + '-' + this.agregarCeroANumeroDeUnDigito(dateFinalMes.getDate()));
    }
    /**
     * Get fecha inicio anio
     */
    getFechaInicioAnio = () => {
        let date = new Date();
        let dateInicioAnio = new Date(date.getFullYear(), 0, 1);
        return (dateInicioAnio.getFullYear() + '-' + this.agregarCeroANumeroDeUnDigito(dateInicioAnio.getMonth() + 1) + '-' + this.agregarCeroANumeroDeUnDigito(dateInicioAnio.getDate()));
    }
    /**
     * Get fecha final anio
     */
     getFechaFinalAnio = () => {
        let date = new Date();
        let dateFinalAnio = new Date(date.getFullYear(), 11, 31);
        return (dateFinalAnio.getFullYear() + '-' + this.agregarCeroANumeroDeUnDigito(dateFinalAnio.getMonth() + 1) + '-' + this.agregarCeroANumeroDeUnDigito(dateFinalAnio.getDate()));
    }
    /**
     * Busqueda nombre manual
     */
     setNombreBusqueda = (nombre) => {
         this.setState({busquedaNombre: nombre});
     }
    /**
     * OnChange form fecha desde
     */
     onChangeFormFechaDesde = (texto) => {
        this.setState({formFechaDesde: texto.target.value});
        if(texto.target.value !== ""){
            this.setState({formFechaDesdeError: false, formFechaDesdeErrorTexto: ""});
        }
        this.setNombreBusqueda('manual');
     }
    /**
     * OnChange form fecha hasta
     */
    onChangeFormFechaHasta = (texto) => {
        this.setState({formFechaHasta: texto.target.value});
        if(texto.target.value !== ""){
            this.setState({formFechaHastaError: false, formFechaHastaErrorTexto: ""});
        }
        this.setNombreBusqueda('manual');
    }
    /**
     * Validar campo vacio
     */
    campoEstaVacio = (texto) => {
        return (texto === '' || texto === null || texto === undefined);
    }
    /**
     * Agregar 0 a numero de un digito
     */
    agregarCeroANumeroDeUnDigito = (numero) => {
        let num = Number(numero);
        if(num < 10){
            num = '0'+num;
        }
        return num;
    }
    /**
     * Deshabilitar el boton generar reporte
     */
     deshabilitarBtnGenerarReporte = (valor) => {
        this.setState({disabledBtnGenerarReporte: valor});
    }
    /**
     * Message
     */
     showMessage(tipo, texto){
        this.setState({openMessage: true, tipoMessage: tipo, textoMessage: texto});
    }
    closeMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openMessage: false});
    }
    
    render(){
        return(
            <div>
                <div style={{marginTop: '15px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <FormControl style={{width: '100%', margin: '1px'}}>
                                <Select 
                                    id="grupoSeleccionarBusqueda"
                                    native  
                                    value={this.state.busquedaNombre}
                                    onChange={this.onChangeBusquedaNombre}>
                                        <optgroup label="Presente">
                                            <option value={'esteMes'}>Este mes</option>
                                            <option value={'esteAnio'}>Este año</option>
                                        </optgroup>
                                        <optgroup label="Manual">
                                            <option value={'manual'}>Manual</option>
                                        </optgroup>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{display: 'flex'}}>
                                <div style={{marginTop: '5px', marginRight: '10px'}}>
                                    <MuiThemeProvider theme={theme}>
                                        <Typography variant="body1" component="p">
                                            Desde
                                        </Typography>
                                    </MuiThemeProvider>
                                </div>
                                <TextField
                                    id="formFechaDesde"
                                    type="date"
                                    value={this.state.formFechaDesde}
                                    error={this.state.formFechaDesdeError}
                                    helperText={this.state.formFechaDesdeErrorTexto}
                                    onChange={this.onChangeFormFechaDesde}
                                    style={{marginLeft: '1px', marginRight: '1px', width: '100%'}}
                                    inputProps={{ min: this.state.fechaHaceUnAnio }}
                                    InputLabelProps={{ shrink: false }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{display: 'flex'}}>
                                <div style={{marginTop: '5px', marginRight: '10px'}}>
                                    <MuiThemeProvider theme={theme}>
                                        <Typography variant="body1" component="p">
                                            Hasta
                                        </Typography>
                                    </MuiThemeProvider>
                                </div>
                                <TextField
                                    id="formFechaHasta"
                                    type="date"
                                    value={this.state.formFechaHasta}
                                    error={this.state.formFechaHastaError}
                                    helperText={this.state.formFechaHastaErrorTexto}
                                    onChange={this.onChangeFormFechaHasta}
                                    style={{marginLeft: '1px', marginRight: '1px', width: '100%'}}
                                    InputLabelProps={{
                                        shrink: false
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <div style={{position: "relative"}}>
                                <BotonVerdeOutlined
                                            texto={"GENERAR REPORTE"}
                                            type={"button"}
                                            eventoClick={this.generarReporte}
                                            disabledBtn={this.state.disabledBtnGenerarReporte} />
                                {this.state.disabledBtnGenerarReporte && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/**
                 * Mensaje resultado
                 */}
                <Snackbar   open={this.state.openMessage}
                            autoHideDuration={10000}
                            onClose={this.closeMessage}>
                    <Alert  onClose={this.closeMessage}
                            severity={this.state.tipoMessage}>
                        {this.state.textoMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}