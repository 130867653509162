import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import './CardTicket.css';
import tgLetraColor from '../../assets/img/icons/tgLetraColor.svg';
import LinkMaterial from '@material-ui/core/Link';
import {Typography,
        Grid,
        Card,
        CardActions,
        CardContent,
        Divider
    } from '@material-ui/core';

export default class CardTicket extends React.Component {
    
    render(){
        return (
            <Card style={{minWidth: 300, paddingBottom: 15}} variant="outlined">
                <CardContent>
                    <Grid container>
                        <Grid>
                            <img src={tgLetraColor} alt="Logo tecnigo" style={{width: "130px"}}/>
                        </Grid>
                        <Grid>
                            <div style={{marginLeft: "5px", marginTop: "5px"}}>
                                <Typography variant="caption" component="p">
                                    958310831
                                </Typography>
                                <Typography variant="caption" component="p">
                                    tecnigo@lobosistemas.com
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Divider />
                    <div style={{marginTop: "5px"}}>
                        <div style={{backgroundColor: "#00A775", padding: "4px"}}>
                            <Typography variant="body2" component="p" style={{color: "#fff"}}>
                                SOLICITUD
                            </Typography>
                        </div>
                        <div>
                            <div>
                                <Grid container>
                                    <Grid>
                                        <div style={{backgroundColor: "#E5E8E8", width: "70px", paddingLeft: "4px", paddingTop: "2px", paddingBottom: "2px"}} >
                                            <Typography variant="body2" component="p">
                                                Código
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                            {this.props.solicitudCod}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </div>
                            <div>
                                <Grid container>
                                    <Grid>
                                        <div style={{backgroundColor: "#E5E8E8", width: "70px", paddingLeft: "4px", paddingTop: "2px", paddingBottom: "2px"}} >
                                            <Typography variant="body2" component="p">
                                                Fecha
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                            {this.props.solicitudFecha}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </div>
                            <div>
                                <Grid container>
                                    <Grid>
                                        <div style={{backgroundColor: "#E5E8E8", width: "70px", paddingLeft: "4px", paddingTop: "2px", paddingBottom: "2px"}} >
                                            <Typography variant="body2" component="p">
                                                Estado
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                            {this.props.solicitudEstado}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </div>
                            <div>
                                <Grid container>
                                    <Grid>
                                        <div style={{backgroundColor: "#E5E8E8", width: "70px", paddingLeft: "4px", paddingTop: "2px", paddingBottom: "2px"}} >
                                            <Typography variant="body2" component="p">
                                                Tipo
                                            </Typography>
                                        </div>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="body2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                            {this.props.solicitudTipo}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </div>
                            <div>
                                <Grid container>
                                    <Grid>
                                        <Typography variant="body2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                            {this.props.nombreEmpresa}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider />
                            </div>
                        </div>
                        <div style={{backgroundColor: "#00A775", padding: "4px"}}>
                            <Typography variant="body2" component="p" style={{color: "#fff"}}>
                                DESCRIPCIÓN
                            </Typography>
                        </div>

                        <div>
                            <Grid container>
                                <Grid>
                                    <div style={{backgroundColor: "#E5E8E8", width: "70px", paddingLeft: "4px", paddingTop: "2px", paddingBottom: "2px"}} >
                                        <Typography variant="body2" component="p">
                                            Precio
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid>
                                    <Typography variant="body2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                        S/ {this.props.precioSoles}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </div>
                        <div style={{height: "150px"}}>
                            <Grid container>
                                <Grid>
                                    <Typography variant="body2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                        {this.props.solicitudTituloDetalle.substring(0,170)}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </div>
                        {/**
                         * Total
                         */}
                         <div style={{marginTop: "10px"}}>
                            <Divider />
                            <Grid container>
                                <Grid>
                                    <div style={{backgroundColor: "#E5E8E8", width: "70px", paddingLeft: "4px", paddingTop: "2px", paddingBottom: "2px"}} >
                                        <Typography variant="subtitle2" component="p">
                                            Total
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid>
                                    <Typography variant="subtitle2" component="p" style={{paddingLeft: "5px", paddingTop: "2px", paddingBottom: "2px"}}>
                                        S/ {this.props.precioSoles}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Divider />
                        </div>
                    </div>
                </CardContent>
                <CardActions>
                    {
                        (this.props.estadoTicket)?
                        <LinkMaterial   href={this.props.urlPdfTicket} 
                                target="_blank" 
                                className="cardTicketBotonTicket"
                                underline="none">
                            <GetAppIcon  style={{marginBottom: "-7px", marginRight: "5px"}}/> TICKET
                        </LinkMaterial>
                        :
                        <Typography variant="body1" component="p" style={{color: "grey"}}>
                            <GetAppIcon  style={{marginBottom: "-7px", marginRight: "5px"}}/> TICKET
                        </Typography>
                    }
                </CardActions>
            </Card>
        )
    }
}
