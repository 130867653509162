import React from 'react';
import axios from '../../utils/axios';

import ErrorIcon from '@material-ui/icons/Error';

import { withRouter } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

class LoginCard extends React.Component {

  constructor(props){
    super(props)
    this.state = {
      formCorreo: "",
      formCorreoError: false,
      formCorreoErrorTexto: "",
      formContrasenia: "",
      formContraseniaError: false,
      formContraseniaErrorTexto: "",
      dataIncorrecta: false,
      disabledBtn: false,
      showPassword: false
    }
  }
  /**
   * Form Correo
   */
  onChangeFormCorreo = (valor) => {
    this.setState({formCorreo: valor.target.value});
    if(valor.target.value !== ""){
      this.setState({formCorreoError: false, formCorreoErrorTexto: ""});
    }
  }
  /**
   * Form Contrasenia
   */
  onChangeFormContrasenia = (valor) => {
    this.setState({formContrasenia: valor.target.value});
    if(valor.target.value !== ""){
      this.setState({formContraseniaError: false, formContraseniaErrorTexto: ""});
    }
  }
  /**
   * Login
   */
  login = (e) => {
    e.preventDefault();
    this.setState({dataIncorrecta: false});
    /**
     * Validar
     */
    let validado = true;
    /**
     * Validar correo
     */
    if(this.state.formCorreo === ""){
      this.setState({formCorreoError: true, formCorreoErrorTexto: "Por favor ingresar un correo válido"});
      validado = false;
    }
    if(this.state.formCorreo.length > 200){
      this.setState({formCorreoError: true, formCorreoErrorTexto: "Por favor ingresar un correo válido"});
      validado = false;
    }
    if(this.state.formCorreo !== "" && this.state.formCorreo.length < 4){
      this.setState({formCorreoError: true, formCorreoErrorTexto: "Por favor ingresar un correo válido"});
      validado = false;
    }
    if(!(/$^|.+@.+..+/).test(this.state.formCorreo)){
      this.setState({formCorreoError: true, formCorreoErrorTexto: "Por favor ingresar un correo válido"});
      validado = false;
    }
    /**
     * Validar contrasenia
     */
    if(this.state.formContrasenia === ""){
      this.setState({formContraseniaError: true, formContraseniaErrorTexto: "Por favor ingresar una contraseña válida"});
      validado = false;
    }
    if(this.state.formContrasenia.length > 100){
      this.setState({formContraseniaError: true, formContraseniaErrorTexto: "Por favor ingresar una contraseña válida"});
      validado = false;
    }
    if(this.state.formContrasenia !== "" && this.state.formContrasenia.length < 4){
      this.setState({formContraseniaError: true, formContraseniaErrorTexto: "Por favor ingresar una contraseña válida"});
      validado = false;
    }
    /**
     * Autenticar
     */
    if(validado){
      this.setState({
        disabledBtn: true
      });
      const datos = {
        nombreUsuario: this.state.formCorreo.trim(),
        contrasenia:  this.state.formContrasenia,
        recordame: false
      }
      axios.post('api/autenticar', datos)
        .then(response =>{
          localStorage.setItem("tokenuser",response.data.id_token);
          const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
          axios.get(`usuario/usuarios/${datos.nombreUsuario}`, { headers: { Authorization: authString } })
          .then(response =>{
              localStorage.setItem("data_user",JSON.stringify(response))
              this.props.history.push("/home");
          })
          .catch(error => {
            this.setState({
                disabledBtn: false,
                dataIncorrecta: true
              });
          });
      })
      .catch(error => {
        this.setState({
          disabledBtn: false,
          dataIncorrecta: true
        });
      });

    }

  }

  /**
   * Show password
   */
  handleClickShowPassword = () => {  
    this.setState({showPassword: !this.state.showPassword});
  };

  /**
   * Mouse Down Password
   */
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  render() {

      return (
          <div>
              <form onSubmit={this.login} autoComplete="off">
                <TextField      
                  name="correo"
                  label="Correo electrónico"
                  variant="outlined"
                  value={this.state.formCorreo}
                  error={this.state.formCorreoError}
                  helperText={this.state.formCorreoErrorTexto}
                  onChange={this.onChangeFormCorreo}
                  margin="normal"
                  fullWidth/>

                <TextField   
                  name="contrasenia"
                  label="Contraseña"
                  variant="outlined"
                  value={this.state.formContrasenia}
                  error={this.state.formContraseniaError}
                  helperText={this.state.formContraseniaErrorTexto}
                  onChange={this.onChangeFormContrasenia}
                  margin="normal"
                  fullWidth
                  type={this.state.showPassword ? 'text' : 'password'}
                  InputProps={{
                      endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword}
                              onMouseDown={this.handleMouseDownPassword}>
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                      )
                  }}
                  />

              <div style={{marginTop: "30px", position: "relative"}}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    disabled={this.state.disabledBtn}>
                    <Typography variant="button" component="p" style={{margin: "3px"}}>
                      Iniciar Sesión
                    </Typography>
                  </Button>
                  {this.state.disabledBtn && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                </div>
                <br></br>
                <div>
                  {
                    (this.state.dataIncorrecta)
                    ?
                    <div style={{display: "inline-flex", width: "100%", justifyContent: "start", color: "#f44336"}}>
                      <ErrorIcon fontSize="small" style={{marginRight: "5px"}}/>
                      <Typography variant="subtitle2" component="p">
                        Credenciales incorrectas
                      </Typography>
                    </div>
                    :<></>
                  }
                </div>
            </form>
          </div>
      );
  }
}

export default withRouter(LoginCard)