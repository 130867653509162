import React from 'react';
import { Link } from 'react-router-dom';
import './BotonVerdeLink.css';

export default class BotonVerdeLink extends React.Component {
    render(){
        return(
            <Link to={this.props.ruta} className="botonVerdeLinkBotonPersonalizado">
                {this.props.nombre}
            </Link>
        )
    }
}