import React from 'react';
import axios from '../../utils/axios';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';

import BotonVerde from '../general/BotonVerde';

import BanderaPE from '../../assets/img/PE.png';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class FormUpdatePerfil extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            formNombres: "",
            formNombresError: false,
            formNombresErrorTexto: "",
            formApellidos: "",
            formApellidosError: false,
            formApellidosErrorTexto: "",
            formCelular: "",
            formCelularError: false,
            formCelularErrorTexto: "",
            formCorreo: "",
            formCorreoError: false,
            formCorreoErrorTexto: "",
            formDni: "",
            formDniError: false,
            formDniErrorTexto: "",
            usuarioCod: null,
            roles: [],
            disabledBtnGuardarCambios: false,
            openMessage: false,
            tipoMessage: "success",
            textoMessage: "",
            abrirProgreso: false
        }
    }
    componentDidMount(){
        // Abrir vista progreso
        this.abrirVistaProgreso(true);
        // Datos del usuario
        this.getDatosUsuario();
    }
    /**
     * Datos del usuario
     */
    getDatosUsuario() {
        const dataUser = localStorage.getItem('data_user');
        const usuarioJSON = JSON.parse(dataUser).data;
        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
            axios.get(`usuario/usuarios/${usuarioJSON.usuarioCorreo}`, { headers: { Authorization: authString } })
            .then(res =>{
                this.setState({
                    usuarioCod: res.data.usuarioCod,
                    roles: res.data.roles,
                    formNombres: res.data.usuarioNombre,
                    formApellidos: res.data.usuarioApellido,
                    formDni: res.data.usuarioDni,
                    formCorreo: res.data.usuarioCorreo,
                    formCelular: (res.data.usuarioCelular === null)? '': res.data.usuarioCelular.substring(3)
                });
                this.props.setCorreo(res.data.usuarioCorreo);
                // Cerrar vista progreso
                this.abrirVistaProgreso(false);
            })
            .catch(error => {
                // Cerrar vista progreso
                this.abrirVistaProgreso(false);
                console.log(error);
            });
    }
    /**
     * Form Nombres
     */
    onChangeFormNombres = (valor) => {
        this.setState({formNombres: valor.target.value});
        if(valor.target.value !== ""){
        this.setState({formNombresError: false, formNombresErrorTexto: ""});
        }
    }
    // Validar Nombre
    validarNombre = (val) => {
        if(val !== "" && !(/^[A-Za-z\-0-9 _’'‘ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]+$/).test(val)){
        this.setState({formNombresError: true, formNombresErrorTexto: "El nombre no puede tener caracteres especiales"});
        return false;
        }
        if(val === ""){
        this.setState({formNombresError: true, formNombresErrorTexto: "Este campo es requerido"});
        return false;
        }
        if(val.length > 50){
        this.setState({formNombresError: true, formNombresErrorTexto: "Máximo 50 caracteres"});
        return false;
        }
        return true;
    }
    /**
     * Form Apellidos
     */
    onChangeFormApellidos = (valor) => {
        this.setState({formApellidos: valor.target.value});
        if(valor.target.value !== ""){
        this.setState({formApellidosError: false, formApellidosErrorTexto: ""});
        }
    }
    // Validar apellido
    validarApellido = (val) => {
        if(val !== "" && !(/^[A-Za-z\-0-9 _’'‘ÆÐƎƏƐƔĲŊŒẞÞǷȜæðǝəɛɣĳŋœĸſßþƿȝĄƁÇĐƊĘĦĮƘŁØƠŞȘŢȚŦŲƯY̨Ƴąɓçđɗęħįƙłøơşșţțŧųưy̨ƴÁÀÂÄǍĂĀÃÅǺĄÆǼǢƁĆĊĈČÇĎḌĐƊÐÉÈĖÊËĚĔĒĘẸƎƏƐĠĜǦĞĢƔáàâäǎăāãåǻąæǽǣɓćċĉčçďḍđɗðéèėêëěĕēęẹǝəɛġĝǧğģɣĤḤĦIÍÌİÎÏǏĬĪĨĮỊĲĴĶƘĹĻŁĽĿʼNŃN̈ŇÑŅŊÓÒÔÖǑŎŌÕŐỌØǾƠŒĥḥħıíìiîïǐĭīĩįịĳĵķƙĸĺļłľŀŉńn̈ňñņŋóòôöǒŏōõőọøǿơœŔŘŖŚŜŠŞȘṢẞŤŢṬŦÞÚÙÛÜǓŬŪŨŰŮŲỤƯẂẀŴẄǷÝỲŶŸȲỸƳŹŻŽẒŕřŗſśŝšşșṣßťţṭŧþúùûüǔŭūũűůųụưẃẁŵẅƿýỳŷÿȳỹƴźżžẓ]+$/).test(val)){
        this.setState({formApellidosError: true, formApellidosErrorTexto: "El apellido no puede tener caracteres especiales"});
        return false;
        }
        if(val === ""){
        this.setState({formApellidosError: true, formApellidosErrorTexto: "Este campo es requerido"});
        return false;
        }
        if(val.length > 100){
        this.setState({formApellidosError: true, formApellidosErrorTexto: "Máximo 100 caracteres"});
        return false;
        }
        return true;
    }
    /**
     * Form Celular
     */
    onChangeFormCelular = (valor) => {
        this.setState({formCelular: valor.target.value});
        if(valor.target.value !== ""){
        this.setState({formCelularError: false, formCelularErrorTexto: ""});
        }
    }
    // Validar celular
    validarCelular = (val) => {
        if(val === ""){
        this.setState({formCelularError: true, formCelularErrorTexto: "Este campo es requerido"});
        return false;
        }
        if((/ /).test(val)){
        this.setState({formCelularError: true, formCelularErrorTexto: "El N° de celular no debe tener espacios en blanco"});
        return false;
        }else{
        const numeroCelular = Number(val);
        if(numeroCelular === undefined || numeroCelular === null || isNaN(numeroCelular)){
            this.setState({formCelularError: true, formCelularErrorTexto: "El N° de celular no debe tener letras"});
            return false;
        }
        }
        if(val.length > 9){
        this.setState({formCelularError: true, formCelularErrorTexto: "El N° de celular debe tener máximo 9 caracteres"});
        return false;
        }
        if(val !== "" && val.length < 9){
        this.setState({formCelularError: true, formCelularErrorTexto: "El N° de celular debe tener mínimo 9 caracteres"});
        return false;
        }
        return true;
    }
    /**
     * Form Dni
     */
    onChangeFormDni = (valor) => {
        this.setState({formDni: valor.target.value});
        if(valor.target.value !== ""){
        this.setState({formDniError: false, formDniErrorTexto: ""});
        }
    }
    // Validar dni
    validarDni = (val) => {
        if(val === ""){
            this.setState({formDniError: true, formDniErrorTexto: "Este campo es requerido"});
            return false;
        }
        if((/ /).test(val)){
            this.setState({formDniError: true, formDniErrorTexto: "El N° de DNI no debe tener espacios en blanco"});
                return false;
        }else{
            const numeroDni = Number(val);
            if(numeroDni === undefined || numeroDni === null || isNaN(numeroDni)){
                this.setState({formDniError: true, formDniErrorTexto: "El N° de DNI no debe tener letras"});
                return false;
            }
        }
        if(val.length > 8){
            this.setState({formDniError: true, formDniErrorTexto: "El N° de DNI debe tener máximo 8 caracteres"});
            return false;
        }
        if(val !== "" && val.length < 8){
            this.setState({formDniError: true, formDniErrorTexto: "El N° de DNI debe tener mínimo 8 caracteres"});
            return false;
        }
        return true;
    }
    /**
     * Form Correo
     */
    onChangeFormCorreo = (valor) => {
        this.setState({formCorreo: valor.target.value});
        if(valor.target.value !== ""){
        this.setState({formCorreoError: false, formCorreoErrorTexto: ""});
        }
    }
    // Validar correo
    validarCorreo = (val) => {
        if(val === ""){
        this.setState({formCorreoError: true, formCorreoErrorTexto: "Este campo es requerido"});
        return false;
        }
        if((/ /).test(val)){
        this.setState({formCorreoError: true, formCorreoErrorTexto: "El correo no debe tener espacios en blanco"});
        return false;
        }
        if(val.length > 200){
        this.setState({formCorreoError: true, formCorreoErrorTexto: "Máximo 200 caracteres"});
        return false;
        }
        if(val !== "" && val.length < 4){
        this.setState({formCorreoError: true, formCorreoErrorTexto: "Mínimo 4 caracteres"});
        return false;
        }
        if(!(/$^|.+@.+..+/).test(val)){
        this.setState({formCorreoError: true, formCorreoErrorTexto: "Ingresar un correo válido"});
        return false;
        }
        return true;
    }
    /**
     * Registrar
     */
    updatePerfil = (e) => {
        e.preventDefault();
        /**
         * Validar
         */
        let validado = true;
        /**
         * Validar nombres
         */
        if(!this.validarNombre(this.state.formNombres)){
            validado = false;
        }
        /**
         * Validar apellidos
         */
        if(!this.validarApellido(this.state.formApellidos)){
            validado = false;
        }
        /**
         * Validar Celular
         */
        if(!this.validarCelular(this.state.formCelular)){
            validado = false;
        }
        /**
         * Validad dni
         */
        if(!this.validarDni(this.state.formDni)){
            validado = false;
        }
        /**
         * Validar correo
         */
        if(!this.validarCorreo(this.state.formCorreo)){
            validado = false;
        }
        /**
         * Registrarse
         */
        if(validado & this.state.usuarioCod !== null){
            this.setState({
                disabledBtnGuardarCambios: true
            });
            const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
            const datos = {
                usuarioCod: this.state.usuarioCod,
                usuarioNombre: this.state.formNombres.toUpperCase().trim(),
                usuarioApellido: this.state.formApellidos.toUpperCase().trim(),
                usuarioCelular: `+51${this.state.formCelular}`,
                usuarioDni: this.state.formDni,
                usuarioCorreo: this.state.formCorreo,
                roles: this.state.roles
            }
            axios.put('usuario/usuarios', datos, { headers: { Authorization: authString } })
                .then( res => {
                    /**
                     * Clear localstorage
                     */
                    localStorage.removeItem('data_user');
                    /**
                     * Save localstorage
                     */
                    localStorage.setItem("data_user",JSON.stringify(res))
                    /**
                     * Datos del usuario
                     */
                    this.getDatosUsuario();
                    this.showMessage("success", "Datos actualizados correctamente");
                    this.setState({
                        disabledBtnGuardarCambios: false
                    });
                })
                .catch( error => {
                    this.setState({
                        disabledBtnGuardarCambios: false
                    });
                    /**
                     * Manejo de errores
                     */
                    if(error.response){
                        const httpErrorResponse = error.response.data;
                        switch(httpErrorResponse.status){
                            case 0:
                                this.showMessage("error", "Servidor no accesible.");
                                break;
                            case 400:
                                if (httpErrorResponse !== '' && httpErrorResponse.fieldErrors) {
                                    const fieldErrors = httpErrorResponse.fieldErrors;
                                    let textoCompleto = "";
                                    let textoErrorEnCampo = "Ingresar datos válidos para el campo: ";
                                    let separador=", ";
                                    let cont = 0;
                                    if(fieldErrors.length > 1){
                                    textoErrorEnCampo = "Ingresar datos válidos para los campos: "
                                    }
                                    for (const fieldError of fieldErrors) {
                                    cont = cont + 1;
                                    const convertedField = fieldError.field;
                                    const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                                    if(fieldErrors.length === cont){
                                        separador=".";
                                    }
                                    textoCompleto = textoCompleto + fieldName + separador;
                                    }
                                    this.showMessage("error", textoErrorEnCampo + textoCompleto);
                                } else if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                    if(httpErrorResponse.message === "error.usuariocodisnull"){
                                        this.showMessage("error", "El usuario no existe");
                                    } else if (httpErrorResponse.message === "error.correotieneespaciosenblanco") {
                                        this.showMessage("error", "El correo tiene espacios en blanco");
                                    } else if (httpErrorResponse.message === "error.emailexists") {
                                        this.showMessage("error", "El correo ya está registrado");
                                    } else if (httpErrorResponse.message === "error.usuarionotexist") {
                                        this.showMessage("error", "El usuario seleccionado no existe");
                                    }else{
                                        this.showMessage("error", httpErrorResponse.message);
                                    }
                                } else {
                                    this.showMessage("error", "Recargar e ingresar todos los datos.");
                                }
                                break;
                            case 404:
                                this.showMessage("error", "Recurso no encontrado.");
                                break;
                            default:
                                if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                    this.showMessage("error", httpErrorResponse.message);
                                } else {
                                    this.showMessage("error", "Error en el servidor.");
                                }
                        }
                    }else{
                        this.showMessage("warning", "No tiene conexión a internet");
                    }
                });
        }
    }
    /**
     * Message
     */
    showMessage(tipo, texto){
        this.setState({openMessage: true, tipoMessage: tipo, textoMessage: texto});
    }
    closeMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openMessage: false});
    }
    /**
     * Abrir vista progreso
     */
    abrirVistaProgreso = (abrir) => {
        this.setState({abrirProgreso: abrir});
    }

    render(){

        return(
            <div>
                <Backdrop style={{zIndex: '1', color:'white'}} open={this.state.abrirProgreso}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <form onSubmit={this.updatePerfil} autoComplete="off">
                    <div style={{marginTop: '15px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <div>
                                    <Typography variant="body1" component="p">
                                        Nombre
                                    </Typography>
                                    <TextField  name="nombres"
                                                variant="outlined"
                                                size="small"
                                                value={this.state.formNombres}
                                                error={this.state.formNombresError}
                                                helperText={this.state.formNombresErrorTexto}
                                                onChange={this.onChangeFormNombres}
                                                fullWidth
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: '#f2f2f2'
                                                    }
                                                }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <div>
                                    <Typography variant="body1" component="p">
                                        Apellido
                                    </Typography>
                                    <TextField  name="apellidos"
                                                variant="outlined"
                                                size="small"
                                                value={this.state.formApellidos}
                                                error={this.state.formApellidosError}
                                                helperText={this.state.formApellidosErrorTexto}
                                                onChange={this.onChangeFormApellidos}
                                                fullWidth
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: '#f2f2f2'
                                                    }
                                                }}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <div>
                                    <Typography variant="body1" component="p">
                                        DNI
                                    </Typography>
                                    <TextField  name="dni"
                                                variant="outlined"
                                                size="small"
                                                value={this.state.formDni}
                                                error={this.state.formDniError}
                                                helperText={this.state.formDniErrorTexto}
                                                onChange={this.onChangeFormDni}
                                                fullWidth
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: '#f2f2f2'
                                                    }
                                                }}/>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <div>
                                    <Typography variant="body1" component="p">
                                        Correo
                                    </Typography>
                                    <TextField  name="correo"
                                                size="small"
                                                variant="outlined"
                                                value={this.state.formCorreo}
                                                error={this.state.formCorreoError}
                                                helperText={this.state.formCorreoErrorTexto}
                                                onChange={this.onChangeFormCorreo}
                                                fullWidth
                                                InputProps={{
                                                    style: {
                                                        backgroundColor: '#f2f2f2'
                                                    }
                                                }}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{marginTop: '15px'}}>
                        <Typography variant="body1" component="p">
                            Teléfono Móvil
                        </Typography>
                        <div style={{marginTop: '15px'}}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <div>
                                        <Typography variant="body1" component="p" style={{color: '#455a64'}}>
                                            Código de país
                                        </Typography>
                                        <TextField
                                            size="small"
                                            variant="outlined"
                                            disabled
                                            fullWidth
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment>
                                                        <p style={{marginTop:"15px"}}>Perú +51</p>
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment>
                                                        <img    src={BanderaPE}
                                                                style={{width: '26px', height: '18px'}}
                                                                alt="Peru"/>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <div>
                                        <Typography variant="body1" component="p" style={{color: '#455a64'}}>
                                            Número
                                        </Typography>
                                        <TextField  name="celular"
                                                    size="small"
                                                    variant="outlined"
                                                    value={this.state.formCelular}
                                                    error={this.state.formCelularError}
                                                    helperText={this.state.formCelularErrorTexto}
                                                    onChange={this.onChangeFormCelular}
                                                    fullWidth
                                                    InputProps={{
                                                        style: {
                                                            backgroundColor: '#f2f2f2'
                                                        }
                                                    }}/>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div style={{marginTop: '30px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <div style={{position: "relative"}}>
                                    <BotonVerde texto={"GUARDAR CAMBIOS"}
                                                type={"submit"}
                                                disabledBtn={this.state.disabledBtnGuardarCambios}
                                                eventoClick={this.updatePerfil} />
                                    {this.state.disabledBtnGuardarCambios && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
                {/**
                 * Mensaje resultado
                 */}
                <Snackbar   open={this.state.openMessage}
                            autoHideDuration={10000}
                            onClose={this.closeMessage}>
                    <Alert  onClose={this.closeMessage}
                            severity={this.state.tipoMessage}>
                        {this.state.textoMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}