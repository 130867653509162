import React, { Component } from 'react';
import axios from '../../utils/axios';
import './ModalNewSolicitud.css';
import { withSnackbar } from 'notistack';
import SockJS from 'sockjs-client';
import webstomp, * as Stomp from 'webstomp-client';

import { responsiveFontSizes, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from "@material-ui/core/Checkbox";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField, Dialog, Typography, Grid, Button } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import BotonVerde from '../general/BotonVerde';
import ModalNewDireccion from '../home/ModalNewDireccion';
import RecursoWebSocket from '../../utils/RecursoWebSocket';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

class ModalNewSolicitud extends Component {

    constructor(props){
        super(props)
        this.state={
            checked: [],
            formOtro: "",
            formOtroError: false,
            formOtroErrorTexto: "",
            formDireccionCod: "",
            formDireccionCodError: false,
            formDireccionCodErrorTexto: "",
            disabledBtn: false,
            openModalNewDireccion: false,
            listaDireccionesUsuario: [],
        }
    }
    componentDidMount() {
        /**
         * Direcciones del usuario
         */
        this.getDireccionesUsuario();
    }
    /**
     * Obtener las direcciones del usuario
     */
    getDireccionesUsuario = () => {
        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        const dataUser = localStorage.getItem('data_user');
        const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
        /**
         * Obtener la lista de direcciones
         */
        axios.get(`usuario/direcciones/${usuarioCod}`, { headers: { Authorization: authString } })
        .then(res =>{
            this.setState({
                listaDireccionesUsuario : res.data
            })
            /**
             * Obtener la ultima direccion usada por el usuario
             */
            axios.get(`usuario/usuarios/${usuarioCod}/ultima/direccion/utilizada`, { headers: { Authorization: authString } })
            .then(res =>{
                this.setState({
                    formDireccionCod: res.data.cod
                })
            }).catch(error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        });
    }
    /**
     * Seleccionar checked
     */
    selectChecked = (value) => () => {
        const currentIndex = this.state.checked.indexOf(value);
        const newChecked = [...this.state.checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        this.setState({
            checked: newChecked
        });
    };
    /**
     * Form otro
     */
    onChangeFormOtro = (valor) => {
        this.setState({formOtro: valor.target.value});
        if(valor.target.value !== ""){
        this.setState({formOtroError: false, formOtroErrorTexto: ""});
        }
    }
    /**
     * Form direccion
     */
    onChangeFormDireccionCod = (valor) => {
        this.setState({formDireccionCod: valor.target.value});
        if(valor.target.value !== ""){
            this.setState({formDireccionCodError: false, formDireccionCodErrorTexto: ""});
        }
    };
    // Validar otro
    validarOtro = (val) => {
        if(val.length > 350){
            this.setState({formOtroError: true, formOtroErrorTexto: "Máximo 350 caracteres"});
            return false;
        }
        return true;
    }
    // Validar direccion
    validarDireccion = (val) => {
        if(val <= 0){
            this.setState({formDireccionCodError: true, formDireccionCodErrorTexto: "Debe seleccionar una dirección"});
            return false;
        }
        return true;
    }
    /**
     * Registrar
     */
    crearSolicitud = (e) => {
        e.preventDefault();
        /**
         * Validar
         */
        let validado = true;
        /**
         * Validar nombres
         */
        if(!this.validarOtro(this.state.formOtro)){
            validado = false;
        }
        /**
         * Validar direccion
         */
        if(!this.validarDireccion(this.state.formDireccionCod)){
            validado = false;
        }
        /**
         * Registrarse
         */
        if(validado){
            this.setState({
                disabledBtn: true
            });
            var unirDetalle = this.arrayToString(this.state.checked) + " " + ((this.state.formOtro || this.state.formOtro.length > 0)? this.state.formOtro.toUpperCase().trim() + " ":"");
            const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
            const dataUser = localStorage.getItem('data_user');
            const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
            const datos = {
                nombre: (this.props.nombreProblema)? this.props.nombreProblema.toUpperCase().trim(): "",
                detalle: unirDetalle.trim(),
                precio: (this.props.precioServicio)? this.props.precioServicio: 0,
                direccionCod: (this.state.formDireccionCod)? this.state.formDireccionCod:0,
                usuarioCod: usuarioCod
            }
            axios.post('usuario/solicitudes/crear', datos,{ headers: { Authorization: authString } })
                .then(res =>{
                    this.props.enqueueSnackbar('Solicitud creado correctamente', {variant: 'success'});
                    this.props.mostrarLinkConfirmacionSolicitud();
                    this.ocultarModalNewSolicitud();
                    this.setState({
                        disabledBtn: false
                    });
                    const solicitCod = res.data.cod;
                    /**
                     * Send email of the solicit created
                     */
                    axios.get(`usuario/solicitudes/enviar/correo/solicitud/${solicitCod}/creado`, { headers: { Authorization: authString } }).then(r => {});
                    /**
                     * Send data web socket
                     */
                    this.connectWebSocketAndSendSolicitud(res.data);
                })
                .catch(error => {
                    this.ocultarModalNewSolicitud();
                    this.setState({
                        disabledBtn: false
                    });
                    /**
                     * Manejo de errores
                     */
                    if(error.response){
                        const httpErrorResponse = error.response.data;
                        switch(httpErrorResponse.status){
                            case 0:
                                this.props.enqueueSnackbar('Hubo un problema, vuelva a intentarlo', {variant: 'error'});
                                break;
                            case 400:
                                if (httpErrorResponse !== '' && httpErrorResponse.fieldErrors) {
                                    const fieldErrors = httpErrorResponse.fieldErrors;
                                    let textoCompleto = "";
                                    let textoErrorEnCampo = "Ingresar datos válidos para el campo: ";
                                    let separador=", ";
                                    let cont = 0;
                                    if(fieldErrors.length > 1){
                                        textoErrorEnCampo = "Ingresar datos válidos para los campos: "
                                    }
                                    for (const fieldError of fieldErrors) {
                                        cont = cont + 1;
                                        const convertedField = fieldError.field;
                                        const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                                        if(fieldErrors.length === cont){
                                            separador=".";
                                        }
                                        textoCompleto = textoCompleto + fieldName + separador;
                                    }
                                    this.props.enqueueSnackbar(textoErrorEnCampo + textoCompleto, {variant: 'error'});
                                } else if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                    if(httpErrorResponse.message === "error.usuario_cod_null"){
                                        this.props.enqueueSnackbar('Hubo un problema con el usuario, vuelva a iniciar sesión', {variant: 'error'});
                                    }else if(httpErrorResponse.message === "error.direccion_cod_null"){
                                        this.props.enqueueSnackbar('Hubo un problema con la dirección, no se ha encontrado la dirección de atención', {variant: 'error'});                                    
                                    }else if(httpErrorResponse.message === "error.usuario_no_existe"){
                                        this.props.enqueueSnackbar('El usuario no existe, vuelva a iniciar sesión', {variant: 'error'});
                                    }else if(httpErrorResponse.message === "error.direccion_no_existe"){
                                        this.props.enqueueSnackbar('La dirección no existe, agregue una nueva dirección de atención', {variant: 'error'});                                    
                                    }else if(httpErrorResponse.message === "error.empresa_bloqueado_falta_pago"){
                                        this.props.getEmpresaBloqueado();
                                        this.props.enqueueSnackbar( 'Solicitud NO creado, por favor regularizar pagos', {variant: 'error', autoHideDuration: 30000, action: this.closeAction});
                                    }else{
                                        this.props.enqueueSnackbar(httpErrorResponse.message, {variant: 'error'});
                                    }
                                } else {
                                    this.props.enqueueSnackbar('Hubo un problema, recargue la página y vuelva a intentarlo', {variant: 'error'});
                                }
                                break;
                            case 404:
                                    this.props.enqueueSnackbar('Hubo un problema, vuelva a intentarlo', {variant: 'error'});
                                break;
                            default:
                                if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                    this.props.enqueueSnackbar(httpErrorResponse.message, {variant: 'error'});
                                } else {
                                    this.props.enqueueSnackbar('Hubo un problema al crear la solicitud, vuelva a intentarlo', {variant: 'error'});
                                }
                        }
                    }else{
                        this.props.enqueueSnackbar('No tiene conexión a internet', {variant: 'warning'});
                    }
                });
        }
    }
    /**
     * Ocultar modal new solicitud
     */
    ocultarModalNewSolicitud = () => {
        this.setState({checked: [], formOtro: ""});
        this.props.ocultarModalNewSolicitud();
    }
    /**
     * Mostrar modal new direccion
     */
    mostrarModalNewDireccion = () => {
        this.setState({openModalNewDireccion:true});
    }
    /**
     * Ocultar modal new direcciones
     */
    ocultarModalNewDirecciones = () => {
        this.setState({openModalNewDireccion: false});
    }
    /**
     * Convertir array to string
     */
    arrayToString(arrayChecked) {
        var selectProblemaNombre = "";
        var cont = 0;
        arrayChecked.map(c => {
            cont = cont + 1;
            if(arrayChecked.length === cont){
                selectProblemaNombre = selectProblemaNombre + c.nombre;
            }else{
                selectProblemaNombre = selectProblemaNombre + c.nombre + ", ";
            }
            return selectProblemaNombre;
        });
        return selectProblemaNombre;
    }

    /**
     * Conectarse web socket y enviar datos de la solicitud
     */
    connectWebSocketAndSendSolicitud(solicitud) {
        const socket = new SockJS( `${RecursoWebSocket.baseURL}websocket/tracker`);
        // Para produccion, no se ve en consola
        const options = {debug: false, protocols: webstomp.VERSIONS.supportedProtocols()};
        const stompClient = Stomp.over(socket, options);
        // Para desarrollo, se ve los resultados en la consola
        //const stompClient = Stomp.over(socket);
        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        stompClient.connect({ headers: { Authorization: authString } }, () => {
            stompClient.send('/topic/solicitud/creado', JSON.stringify({cod: solicitud.cod}));
            stompClient.disconnect();
        });
    }
    /**
     * Accion del Snacbar
     */
    closeAction = key => (
        <div>
            <IconButton onClick={() => { this.props.closeSnackbar(key) }}>
                <CloseIcon />
            </IconButton>
        </div>
    );
  
    render() {
        return (
            <div>
                <Dialog open={this.props.openModalNewSolicitud}
                        fullWidth={true}
                        disableBackdropClick={true}
                        disableEscapeKeyDown={true}>
                    <DialogTitle onClose={this.ocultarModalNewSolicitud}>
                        <div>
                            <MuiThemeProvider theme={theme}>
                                <Typography variant="h5" component="h2" style={{fontWeight: 'bold'}}>
                                    Crear solicitud
                                </Typography>
                            </MuiThemeProvider>
                        </div>
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container>
                            <MuiThemeProvider theme={theme}>
                                <Typography variant="body2" component="p" style={{margin: "5px", fontWeight: 'bold'}}>
                                    {this.props.nombreProblema}
                                </Typography>
                            </MuiThemeProvider>
                        </Grid>
                        <Grid container>
                            <Grid>
                                <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                    Marca los posibles problemas
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="subtitle2" component="p" style={{margin: "5px"}}>
                                    (Opcional)
                                </Typography>
                            </Grid>
                        </Grid>
                        <List style={{width: '100%', marginTop: "-5px"}}>
                            {this.props.listaOpciones.map((opcion, i) => {
                                return (
                                <ListItem key={i} 
                                            role={undefined} dense button 
                                            onClick={this.selectChecked(opcion)}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={this.state.checked.indexOf(opcion) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                        />
                                    </ListItemIcon>
                                    <ListItemText id={opcion.cod} primary={`${opcion.nombre}`} />
                                </ListItem>
                                );
                            })}
                        </List>
                        {
                            /**
                             * Formulario
                             */
                        }
                        <form onSubmit={this.crearSolicitud} autoComplete="off">

                            <div style={{marginBottom: "10px", marginTop: "-5px"}}>
                                <Grid container>
                                    <Grid>
                                        <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                            Otro
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="subtitle2" component="p" style={{margin: "5px"}}>
                                            (Opcional)
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <TextField      
                                    name="otro"
                                    variant="outlined"
                                    size="small"
                                    value={this.state.formOtro}
                                    error={this.state.formOtroError}
                                    helperText={this.state.formOtroErrorTexto}
                                    onChange={this.onChangeFormOtro}
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            backgroundColor: '#f2f2f2'
                                        }
                                    }}/>
                            </div>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Grid container>
                                        <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                            Agregar una dirección de atención
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                </Grid>
                                <Grid item xs={12} sm={4} style={{marginBottom: '5px'}}>
                                    <div className="modalNewSolicitudContenedorBotonDireccion">
                                        <Button variant="contained"
                                                color="primary"
                                                onClick={this.mostrarModalNewDireccion}> 
                                            ADD DIRECCIÓN
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            <div>
                                <Grid container>
                                    <Grid>
                                        <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                            Seleccionar una dirección
                                        </Typography>
                                    </Grid>
                                    <Grid>
                                        <Typography variant="subtitle2" component="p" style={{margin: "5px"}}>
                                            (Requerido)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <div>
                                <TextField  select
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            value={this.state.formDireccionCod}
                                            onChange={this.onChangeFormDireccionCod}
                                            error={this.state.formDireccionCodError}
                                            helperText={this.state.formDireccionCodErrorTexto}
                                            InputProps={{
                                                style: {
                                                    backgroundColor: '#f2f2f2'
                                                }
                                            }}>
                                            {this.state.listaDireccionesUsuario.map((dir) => (
                                                <MenuItem key={dir.cod} value={dir.cod}>
                                                    <Typography variant="body2" component="p">
                                                        {(dir.nombre)? dir.nombre.toUpperCase() : ""}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                </TextField>
                            </div>
                            <div style={{marginTop: "40px",marginBottom: "10px", position: "relative"}}>
                                <BotonVerde texto={"CREAR SOLICITUD"}
                                            type={"submit"}
                                            disabledBtn={this.state.disabledBtn}/>
                                {this.state.disabledBtn && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                            </div>
                        </form>
                        {
                            /**
                             * Fin de formulario
                             */
                        }
                    </DialogContent>
                </Dialog>
                {
                    /**
                     * Nueva direccion
                     */
                }
                <ModalNewDireccion
                    openModalNewDireccion = {this.state.openModalNewDireccion}
                    ocultarModalNewDirecciones = {this.ocultarModalNewDirecciones}
                    getDireccionesUsuario = {this.getDireccionesUsuario}/>
            </div>
        )
    }

}

export default withSnackbar(ModalNewSolicitud);
