import React from 'react';
import './NotFound.css';

export default class NotFound extends React.Component {
    render(){
        return (
            <div className="notFoundFondo">
                <div className="notFoundTexto">
                    <h2>Hmmm!</h2>
                    <p>No encontramos lo que buscabas.</p>
                </div>
            </div>
        )
    }
}