import React from 'react';
import { StaticGoogleMap, Marker } from 'react-static-google-map';

import Credenciales from '../../utils/Credenciales';

export default class StaticMap extends React.Component {

    render(){
        return(
            <div>
                <StaticGoogleMap
                    size="260x310"
                    apiKey={Credenciales.mapsKey}
                    zoom="17">
                    <Marker 
                            location={{ lat: (this.props.lat)? this.props.lat:0, lng: (this.props.lon)? this.props.lon:0 }}
                            color="green"/>
                </StaticGoogleMap>
            </div>
        )
    }
}