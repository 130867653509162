import React from 'react';
import axios from '../utils/axios';

export default class LoginAdmin extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            tokenuser: new URLSearchParams(window.location.search).get('token'),
            emailuser: new URLSearchParams(window.location.search).get('email'),
            mensaje: 'Cargando...'
        }
    }
    componentDidMount(){
        if(!localStorage.getItem("data_user")){
            const authString = 'Bearer '.concat(this.state.tokenuser);
            axios.get(`usuario/usuarios/${this.state.emailuser}`, { headers: { Authorization: authString } })
            .then(response =>{
                localStorage.setItem("tokenuser", this.state.tokenuser);
                localStorage.setItem("data_user",JSON.stringify(response))
                window.location.reload();
            })
            .catch(error => {
                this.setState({
                    mensaje: 'Error: Failed login by user'
                });
            });
        }
    }
    render(){
        return(
            <div>
                <p>{this.state.mensaje}</p>
            </div>
        );
    }
}