import React from 'react';
import './Perfil.css';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import Layout from '../components/layout/Layout';
import FormUpdatePerfil from '../components/perfil/FormUpdatePerfil';
import DetailEmpresa from '../components/perfil/DetailEmpresa';
import FormUpdatePassword from '../components/perfil/FormUpdatePassword';

import HomeWorkIcon from '@material-ui/icons/HomeWork';
import LockIcon from '@material-ui/icons/Lock';
import LetraColorSimbolo from '../assets/img/icons/letraColorSimbolo.svg';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

export default class Perfil extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            correo: ''
        }
    }

    /**
     * Correo
     */
    setCorreo = (val) => {
        this.setState({correo: val});
    }
  
    render(){
        return(
            <Layout>
                <div className="perfilDegradado">
                    <Container>
                        <div className="perfilContenedorEncabezado">
                            <div style={{marginTop: '40px'}}>
                                <div style={{height: '180px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img    src={LetraColorSimbolo}
                                                className="perfilCirculo"
                                                alt="TecniGo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="perfilContenedorTitulo">
                                <h2 className="perfilEstiloTitulo">Perfil</h2>
                                <h2 className="perfilEstiloSubtitulo">Editar</h2>
                            </div>
                        </div>
                    </Container>
                </div>
                <Container>
                    {/**
                     * Seccion usuario
                     */}
                    <div style={{paddingTop: "20px", marginBottom: "10px"}}>
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="h4" component="h2">
                                Tus datos
                            </Typography>
                        </MuiThemeProvider>
                    </div>
                    <Divider />
                    <FormUpdatePerfil setCorreo={this.setCorreo} />
                    {
                        /**
                         * Seccion empresa
                         */
                    }
                    <div style={{display: "inline-flex", marginTop: '40px', marginBottom: '15px'}}>
                        <HomeWorkIcon style={{color: "#3B3092", marginRight: "5px", marginTop: '3px'}}/>
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="h5" component="h2">
                                Empresa
                            </Typography>
                        </MuiThemeProvider>
                    </div>
                    <Divider />
                    <DetailEmpresa />
                    {
                        /**
                         * Seccion contrasenia
                         */
                    }
                    <div style={{display: "inline-flex", marginTop: '40px', marginBottom: '15px'}}>
                        <LockIcon style={{color: "#3B3092", marginRight: "5px", marginTop: '3px'}}/>
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="h5" component="h2">
                                Correo y contraseña
                            </Typography>
                        </MuiThemeProvider>
                    </div>
                    <Divider />
                    <FormUpdatePassword correo={this.state.correo} />

                </Container>
                <br/><br/> <br/>
            </Layout>
        );
    }
}