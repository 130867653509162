import React from 'react';
import './assets/css/cssgeneral.css';
import CssBaseline from '@material-ui/core/CssBaseline';

import { Switch, Route, Redirect } from "react-router-dom";

import Login from './pages/Login';
import Home from './pages/Home';
import Solicitudes from './pages/Solicitudes';
import SolicitudDetalle from './pages/SolicitudDetalle';
import NotFound from './components/error/NotFound';
import LoginAdmin from './pages/LoginAdmin';
import Perfil from './pages/Perfil';
import Reporte from './pages/Reporte';

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        localStorage.getItem("tokenuser") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}


function PublicRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !localStorage.getItem("tokenuser") ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default function App() {
    return (
        <React.Fragment>
          <CssBaseline />
          <Switch>

            <PublicRoute exact path="/">
              <Login />
            </PublicRoute>
            <PublicRoute exact path="/login/admin">
              <LoginAdmin />
            </PublicRoute>

            <PrivateRoute exact path="/home" >
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path="/perfil" >
              <Perfil />
            </PrivateRoute>
            <PrivateRoute exact path="/reporte" >
              <Reporte />
            </PrivateRoute>
            <PrivateRoute exact path="/solicitudes">
              <Solicitudes />
            </PrivateRoute>
            <PrivateRoute exact path="/solicitudes/:id">
              <SolicitudDetalle />
            </PrivateRoute>

            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </React.Fragment>
    );
}