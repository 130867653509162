import React from 'react';
import axios from '../../utils/axios';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

import BotonVerde from '../general/BotonVerde';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class FormUpdatePassword extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            formContrasenia: "",
            formContraseniaError: false,
            formContraseniaErrorTexto: "",
            disabledBtnGuardarCambios: false,
            openMessage: false,
            tipoMessage: "success",
            textoMessage: "",
            showPassword: false
        }
    }
    /**
     * Form Contrasenia
     */
    onChangeFormContrasenia = (valor) => {
        this.setState({formContrasenia: valor.target.value});
        if(valor.target.value !== ""){
        this.setState({formContraseniaError: false, formContraseniaErrorTexto: ""});
        }
    }
    // Validar contrasenia
    validarContrasenia = (val) => {
        if((/ /).test(val)){
        this.setState({formContraseniaError: true, formContraseniaErrorTexto: "La contraseña no debe tener espacios en blanco"});
        return false;
        }
        if(val === ""){
        this.setState({formContraseniaError: true, formContraseniaErrorTexto: "Este campo es requerido"});
        return false;
        }
        if(val.length > 100){
        this.setState({formContraseniaError: true, formContraseniaErrorTexto: "Máximo 100 caracteres"});
        return false;
        }
        if(val !== "" && val.length < 4){
        this.setState({formContraseniaError: true, formContraseniaErrorTexto: "Mínimo 4 caracteres"});
        return false;
        }
        return true;
    }
    /**
     * Update password
     */
    updatePassword = (e) => {
        e.preventDefault();
        /**
         * Validar
         */
        let validado = true;
        /**
         * Validar contrasenia
         */
        if(!this.validarContrasenia(this.state.formContrasenia)){
            validado = false;
        }
        /**
         * Actualizar contrasenia
         */
        if(validado){
            this.setState({
                disabledBtnGuardarCambios: true
            });
            const dataUser = localStorage.getItem('data_user');
            const usuarioJSON = JSON.parse(dataUser).data;
            const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
            const datos = {
                usuarioCod: usuarioJSON.usuarioCod,
                contrasenia:  this.state.formContrasenia
            }
            axios.post('usuario/usuarios/change-password', datos, { headers: { Authorization: authString } })
                .then( res => {
                    this.showMessage("success", "Contraseña actualizado correctamente");
                    this.setState({
                        formContrasenia: '',
                        disabledBtnGuardarCambios: false
                    });
                })
                .catch( error => {
                    this.setState({
                        disabledBtnGuardarCambios: false
                    });
                    /**
                     * Manejo de errores
                     */
                    if(error.response){
                        const httpErrorResponse = error.response.data;
                        switch(httpErrorResponse.status){
                            case 0:
                                this.showMessage("error", "Servidor no accesible.");
                                break;
                            case 400:
                                if (httpErrorResponse !== '' && httpErrorResponse.fieldErrors) {
                                    const fieldErrors = httpErrorResponse.fieldErrors;
                                    let textoCompleto = "";
                                    let textoErrorEnCampo = "Ingresar datos válidos para el campo: ";
                                    let separador=", ";
                                    let cont = 0;
                                    if(fieldErrors.length > 1){
                                    textoErrorEnCampo = "Ingresar datos válidos para los campos: "
                                    }
                                    for (const fieldError of fieldErrors) {
                                    cont = cont + 1;
                                    const convertedField = fieldError.field;
                                    const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                                    if(fieldErrors.length === cont){
                                        separador=".";
                                    }
                                    textoCompleto = textoCompleto + fieldName + separador;
                                    }
                                    this.showMessage("error", textoErrorEnCampo + textoCompleto);
                                } else if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                    this.showMessage("error", httpErrorResponse.message);
                                } else {
                                    this.showMessage("error", "Recargar e ingresar todos los datos.");
                                }
                                break;
                            case 404:
                                this.showMessage("error", "Recurso no encontrado.");
                                break;
                            default:
                                if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                    this.showMessage("error", httpErrorResponse.message);
                                } else {
                                    this.showMessage("error", "Error en el servidor.");
                                }
                        }
                    }else{
                        this.showMessage("warning", "No tiene conexión a internet");
                    }
                });
        }
    }
    /**
     * Message
     */
    showMessage(tipo, texto){
        this.setState({openMessage: true, tipoMessage: tipo, textoMessage: texto});
    }
    closeMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openMessage: false});
    }

    /**
     * Show password
     */
    handleClickShowPassword = () => {  
        this.setState({showPassword: !this.state.showPassword});
    };

    /**
     * Mouse Down Password
     */
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    render(){
        return(
            <div>
                <form onSubmit={this.updatePerfil} autoComplete="off">
                    <div style={{marginTop: '15px'}}>
                        <Grid container spacing={3} direction='column'>
                            <Grid item xs={12} sm={6} md={3}>
                                <div>
                                    <Typography variant="body1" component="p">
                                        Correo
                                    </Typography>
                                    <TextField  name="correo"
                                                variant="outlined"
                                                size="small"
                                                value={this.props.correo}
                                                disabled
                                                fullWidth
                                                InputProps={{
                                                    style: {
                                                        color: '#3B3945'
                                                    }
                                                }} />
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                                <div>
                                    <Typography variant="body1" component="p">
                                        Nueva contraseña
                                    </Typography>
                                    <TextField  name="contrasenia"
                                                variant="outlined"
                                                size="small"
                                                value={this.state.formContrasenia}
                                                error={this.state.formContraseniaError}
                                                helperText={this.state.formContraseniaErrorTexto}
                                                onChange={this.onChangeFormContrasenia}
                                                fullWidth
                                                type={this.state.showPassword ? 'text' : 'password'}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                          <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={this.handleClickShowPassword}
                                                            onMouseDown={this.handleMouseDownPassword}>
                                                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                                          </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    
                    <div style={{marginTop: '30px'}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} md={3}>
                                <div style={{position: "relative"}}>
                                    <BotonVerde texto={"GUARDAR"}
                                                type={"submit"}
                                                disabledBtn={this.state.disabledBtnGuardarCambios}
                                                eventoClick={this.updatePassword} />
                                    {this.state.disabledBtnGuardarCambios && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </form>
                {/**
                 * Mensaje resultado
                 */}
                <Snackbar   open={this.state.openMessage}
                            autoHideDuration={10000}
                            onClose={this.closeMessage}>
                    <Alert  onClose={this.closeMessage}
                            severity={this.state.tipoMessage}>
                        {this.state.textoMessage}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
}