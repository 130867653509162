import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { SnackbarProvider } from 'notistack';
 
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

const routing = (
  <BrowserRouter basename="/">
    <SnackbarProvider maxSnack={2}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}>
      <App />
    </SnackbarProvider>
  </BrowserRouter>
)

ReactDOM.render(routing, document.getElementById('root'));
/**
 * Este codigo se crea, pero no se debe colocar ya que muestra varios errores
 * ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
 */
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
