import React from 'react';
import { Link } from 'react-router-dom';
import './BotonCirculoAtrasVerdeLink.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export default class BotonCirculoAtrasVerdeLink extends React.Component {
    render(){
        return(
            <Link to={this.props.ruta} className="botonCirculoAtrasVerdeLinkBotonPersonalizado">
                <ArrowBackIosIcon fontSize="small"/>
            </Link>
        )
    }
}