import React from 'react';
import './Login.css';

import tgLetraColor from '../assets/img/icons/tgLetraColor.svg';
import tgLetraBlanco from '../assets/img/icons/tgLetraBlanco.svg';

import Grid from '@material-ui/core/Grid';

import BotonCirculoAtrasMorado from '../components/general/BotonCirculoAtrasMorado';
import LinkPersonalizado from '../components/general/LinkPersonalizado';
import LoginCard from '../components/login/LoginCard';

export default class Login extends React.Component {
    render(){
        return(
            <div>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <div style={{margin: "20px"}}>
                            <BotonCirculoAtrasMorado ruta={"https://www.tecnigo.app/"}/>
                        </div>
                        <div className="loginContenedorRegistro">
                            <div>
                                <img src={tgLetraColor} style={{width: "150px"}} alt="Logo TecniGo"/>
                                <div style={{marginTop: "25px", marginBottom: "20px"}}>
                                    <h2 className="generalTextoH2TituloFormulario">Iniciar sesión</h2>
                                </div>
                            </div>
                            <LoginCard />
                            <div style={{marginTop: "50px", marginBottom: "50px"}}>
                                <hr></hr>
                            </div>
                            <div>
                                <LinkPersonalizado 
                                    texto={"¿No tienes una cuenta?"}
                                    ruta={"https://www.tecnigo.app/registro"}
                                    textoLink={"Regístrate"} />
                            </div>
                            <div style={{marginTop: "20px", marginLeft: "-8px"}}>
                                <LinkPersonalizado 
                                    texto={""}
                                    ruta={"https://www.tecnigo.app/passwordreset"}
                                    textoLink={"¿Se te olvidó tu contraseña?"} />
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <div className="loginContenedorColor">
                            <img src={tgLetraBlanco} style={{width: "150px"}} alt="Logo TecniGo"/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}