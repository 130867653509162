import React from 'react';
import './BotonCirculoAtrasMorado.css';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

export default class BotonCirculoAtrasMorado extends React.Component {
    render(){
        return(
            <a href={this.props.ruta} className="botonCirculoAtrasMoradoBotonPersonalizado">
                <ArrowBackIosIcon fontSize="small"/>
            </a>
        )
    }
}