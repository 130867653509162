import React, { Component } from 'react';
import './SolicitudDetalle.css';
import { CulqiProvider, Culqi } from 'react-culqi';
import axios from '../utils/axios';
import jwt from 'jwt-decode';
import { withRouter } from 'react-router-dom';

import Tarjetas from '../assets/img/cards.png';
import ImagenTecnicoDefaul from '../assets/img/imagenTecnicoDefaul.png';

import WatchLaterIcon from '@material-ui/icons/WatchLater';
import PersonIcon from '@material-ui/icons/Person';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import DescriptionIcon from '@material-ui/icons/Description';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import HomeIcon from '@material-ui/icons/Home';
import GetAppIcon from '@material-ui/icons/GetApp';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {MuiThemeProvider, createMuiTheme, responsiveFontSizes} from '@material-ui/core';

import LinkMaterial from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Container from '@material-ui/core/Container';
import CardContent from '@material-ui/core/CardContent';

import CardTicket from '../components/solicitudDetalle/CardTicket';
import CardTecnico from '../components/solicitudDetalle/CardTecnico';
import CardCalificar from '../components/solicitudDetalle/CardCalificar';
import Layout from '../components/layout/Layout';
import BotonCirculoAtrasVerdeLink from '../components/general/BotonCirculoAtrasVerdeLink';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class SolicitudDetalle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            solicitudCod:Number(this.props.location.pathname.substring(13)),
            valorDefaultRadioGroup: "",
            montoPagoCulqi: 0,
            disabeldBtnCulqi: true,
            asignado: false,
            cancelado: false,
            solicitudEstado: 0,
            pdfUrl: "",
            calificacion: 0,
            tecnicoFotoUrl: "",
            celular: "",
            nroDocumento: "",
            detalle: "",
            direccion: "",
            edad: "",
            especialidad: [],
            fechaCreacion: "",
            fechaPago: "",
            horaAtencion: "",
            montoPago: "",
            nombre: "",
            nombreEmpresa: "",
            nombreTecnico: "",
            pagado: false,
            referencia: "",
            rucEmpresa: "",
            tipoSolicitud: "",
            total: "",
            tieneTicket: false,
            pdfUrlTicket: "",
            empresaDeConfianza: false,
            solicitudPrecioConfirmado: false,
            solicitudSubtrabajosFinalizados: false,
            solicitudSePuedePagar: false,
            openMessage: false,
            tipoMessage: "success",
            textoMessage: "",
            abrirProgreso: false
        }

    }

    componentDidMount() {
        /**
         * Verificar token
         */
        if(this.invalidToken()){
            this.logoutByTokenExpired();
        }
        /**
         * Obtener los detalles
         */
        this.getSolicitudDetalleByCod();
    }
    /**
     * Obtener los detalles de la solicitud por cod
     */
    getSolicitudDetalleByCod = () => {
        /**
         * Verificar token
         */
        if(this.invalidToken()){
            this.logoutByTokenExpired();
        }

        const cod = this.state.solicitudCod;
        if(cod === null || cod === undefined || cod <= 0){
            this.props.history.push("/home");
            // Cerrar vistar progreso
            this.abrirVistaProgreso(false);
        }
        // Abrir vista progreso
        this.abrirVistaProgreso(true);

        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        axios.get(`usuario/solicitudes/detalle/${cod}`, { headers: { Authorization: authString } })
        .then(response => {
            // Cerrar vista progreso
            this.abrirVistaProgreso(false);

            this.setState({
                errorPagoCulqi: false,
                asignado: response.data.asignado,
                cancelado: response.data.cancelado,
                solicitudEstado: response.data.solicitudEstado,
                pdfUrl: response.data.pdfUrl,
                calificacion: response.data.calificacion,
                tecnicoFotoUrl: response.data.tecnicoFotoUrl,
                celular: response.data.celular,
                nroDocumento: response.data.nroDocumento,
                detalle: response.data.detalle,
                direccion: response.data.direccion,
                edad: response.data.edad,
                especialidad: response.data.especialidad,
                fechaCreacion: response.data.fechaCreacion,
                fechaPago: response.data.fechaPago,
                horaAtencion: response.data.horaAtencion,
                montoPago: response.data.montoPago,
                nombre: response.data.nombre,
                nombreEmpresa: response.data.nombreEmpresa,
                nombreTecnico: response.data.nombreTecnico,
                pagado: response.data.pagado,
                referencia: response.data.referencia,
                rucEmpresa: response.data.rucEmpresa,
                tipoSolicitud: response.data.tipoSolicitud,
                total: response.data.total,
                tieneTicket: response.data.tieneTicket,
                pdfUrlTicket: response.data.pdfUrlTicket,
                empresaDeConfianza: response.data.empresaDeConfianza,
                solicitudPrecioConfirmado: response.data.solicitudPrecioConfirmado,
                solicitudSubtrabajosFinalizados: response.data.solicitudSubtrabajosFinalizados,
                solicitudSePuedePagar: response.data.solicitudSePuedePagar
            });
        })
        .catch(error => {
            // Cerrar vista progreso
            this.abrirVistaProgreso(false);
            this.props.history.push("/home");
        });
    }
    /**
     * Set radio group value
     */
    setRadioGroupValue = valor => {
        const totalNumber = Number(valor).toFixed(2);
        const totalCentimos = (totalNumber * 100).toFixed(2);
        const totalCentimosNumber = Number(totalCentimos);
        this.setState({
            valorDefaultRadioGroup : valor,
            nombre: this.state.nombre,
            detalle: this.state.detalle,
            disabeldBtnCulqi: false,
            montoPagoCulqi: totalCentimosNumber
        })
    }
    /**
     * Pagar solicitud
     */
    pagarSolicitud = datos => {
        /**
         * Verificar token
         */
        if(this.invalidToken()){
            this.logoutByTokenExpired();
        }
        
        // Abrir vista progreso
        this.abrirVistaProgreso(true);

        this.setState({disabeldBtnCulqi: true});

        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        axios.post('usuario/solicitudes/pago/con/culqi', datos,  { headers: { Authorization: authString } })
            .then(response =>{
                // Send email with sell ticket
                const solicitCod = (response.data.cod === null || response.data.cod === undefined)? 0 : response.data.cod;
                axios.get(`usuario/solicitudes/${solicitCod}/enviar/ticket/venta`, { headers: { Authorization: authString } }).then(r => {});

                this.getSolicitudDetalleByCod();
            }).catch(error => {
                // Cerrar vista progreso
                this.abrirVistaProgreso(false);

                this.setState({
                    disabeldBtnCulqi: false,
                    errorPagoCulqi: true
                });
                /**
                 * Manejo de errores
                 */
                if(error.response){
                    const httpErrorResponse = error.response.data;
                    switch(httpErrorResponse.status){
                        case 0:
                            this.showMessage("error", "Hubo un problema, revice el pago en su estado de cuenta");
                            break;
                        case 400:
                            if (httpErrorResponse !== '' && httpErrorResponse.fieldErrors) {
                                this.showMessage("error", "Faltan algunos datos, intente pagar nuevamente");
                            } else if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                if(httpErrorResponse.message === "error.solicitud_cod_null"){
                                    this.showMessage("error", "Hubo un problema, solicitud no encontrado");
                                }else if(httpErrorResponse.message === "error.solicitud_no_existe"){
                                    this.showMessage("error", "Hubo un problema, solicitud no encontrado");
                                }else if(httpErrorResponse.message === "error.solicitud_ya_esta_pagado"){
                                    this.showMessage("error", "La solicitud ya está pagado");
                                }else if(httpErrorResponse.message === "error.ticket_venta_ya_existe"){
                                    this.showMessage("error", "Ya existe un ticket de venta");
                                }else if(httpErrorResponse.message === "error.solicitud_precio_no_confirmado"){
                                    this.showMessage("error", "El precio de la solicitud NO está confirmado");
                                }else if(httpErrorResponse.message === "error.trabajo_o_trabajos_no_finalizados"){
                                    this.showMessage("error", "La solicitud tiene trabajos pendientes de finalización");
                                }else if(httpErrorResponse.message === "error.pago_es_menor_al_precio_de_solicitud"){
                                    this.showMessage("error", "El monto a pagar es menor al costo de la solicitud");
                                }else if(httpErrorResponse.message === "error.pago_con_culqi_error"){
                                    this.showMessage("error", "Hubo un problema, revice el pago en su estado de cuenta");
                                }else{
                                    this.showMessage("error", httpErrorResponse.message);
                                }
                            } else {
                                this.showMessage("error", "Hubo un problema, revice el pago en su estado de cuenta");
                            }
                            break;
                        case 404:
                            this.showMessage("error", "Hubo un problema, revice el pago en su estado de cuenta");
                            break;
                        default:
                            if (httpErrorResponse !== '' && httpErrorResponse.message) {
                                this.showMessage("error", httpErrorResponse.message);
                            } else {
                                this.showMessage("error", "Hubo un problema, revice el pago en su estado de cuenta");
                            }
                    }
                }else{
                    this.showMessage("warning", "No tiene conexión a internet");
                }
            });
    }
    /**
     * Abrir vista progreso
     */
     abrirVistaProgreso = (abrir) => {
        this.setState({abrirProgreso: abrir});
    }
    /**
     * ******************************
     * Verificar validacion de token
     * ******************************
     */
    invalidToken = () => {
        const tokenId = localStorage.getItem("tokenuser");
        const decodeToken = jwt(tokenId);
        // Expiracion del token en segundos
        const expTokenSegundos = decodeToken.exp;
        // Fecha actual en segundos
        const todaySegundos = Date.now() / 1000;
        // 1590602256.671 > 1590601904
        return todaySegundos > expTokenSegundos;
    }
    /**
     * logout by token expired
     */
    logoutByTokenExpired = () => {
        localStorage.removeItem('tokenuser');
        localStorage.removeItem('data_user');
        localStorage.removeItem('lista_servicios');
        localStorage.removeItem('datos_empresa');
        this.props.history.push("/");
    }
    /**
     * Message
     */
    showMessage(tipo, texto){
        this.setState({openMessage: true, tipoMessage: tipo, textoMessage: texto});
    }
    closeMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openMessage: false});
    }
    
    render() {

        return ( 
            <Layout>
                <Backdrop style={{zIndex: '1', color:'white'}} open={this.state.abrirProgreso}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className="solicitudDetContenedorDegradadoDetalle">
                    <Container>
                        <Grid container direccion="row">
                            <Grid item xs={12} sm={5} md={8}>
                                <div className="solicitudDetContenedorTexto">
                                    <div>
                                        <BotonCirculoAtrasVerdeLink ruta={"/solicitudes"}/>
                                    </div>
                                    <div className="solicitudDetContenedorTitulo">
                                        <h2 className="solicitudDetEstiloTitulo">Revisar</h2>
                                    </div>
                                    <div className="solicitudDetSubtitulo">
                                        <h2 className="solicitudDetEstiloSubtitulo">
                                            Detalle de la solicitud
                                        </h2>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={7} md={4}>
                                <div className="solicitudDetContenedorCardTicket">
                                    <CardTicket 
                                        solicitudCod={this.state.solicitudCod}
                                        solicitudFecha={this.state.fechaCreacion}
                                        solicitudEstado={(this.state.cancelado)? "CANCELADO": (this.state.pagado)? "PAGADO":"NO PAGADO"}
                                        solicitudTipo={this.state.tipoSolicitud}
                                        nombreEmpresa={this.state.nombreEmpresa}
                                        precioSoles={this.state.total}
                                        solicitudTituloDetalle={this.state.nombre + " " + this.state.detalle}
                                        estadoTicket={(this.state.tieneTicket && this.state.pdfUrlTicket !== "")? true:false}
                                        urlPdfTicket={this.state.pdfUrlTicket}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Container>
                </div>

                <Container>
                    {/**
                     * Titulo
                     */}
                    <div className="solicitudDetContenedorTituloDetalle">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8}>
                                <MuiThemeProvider theme={theme}>
                                    <Typography variant="h4" component="h2" style={{marginBottom: "10px"}}>
                                        Solicitud
                                    </Typography>
                                </MuiThemeProvider>
                                <Divider />
                            </Grid>
                        </Grid>
                    </div>
                    <div className="solicitudDetContenedorGeneralSolicitudDetalle">
                        <Grid container>
                            <Grid item xs={12} sm={12} md={8}>
                                {/**
                                 * Seccion Pago
                                 */}
                                <div>
                                    <Card>
                                        <CardContent>
                                            <div>
                                                <div style={{display: "inline-flex"}}>
                                                    <MonetizationOnIcon style={{color: "#3B3092", marginTop: "2px", marginRight: "5px"}}/>
                                                    <MuiThemeProvider theme={theme}>
                                                        <Typography variant="h5" component="h2">
                                                            Pago
                                                        </Typography>
                                                    </MuiThemeProvider>
                                                </div>
                                            </div>
                                            <div style={{marginTop: "20px"}}>
                                            {
                                                (this.state.cancelado)
                                                ?
                                                <Typography variant="body1" component="p" style={{color: "#f44336"}}>
                                                    SOLICITUD CANCELADA
                                                </Typography>
                                                :
                                                <div>
                                                    {
                                                        (this.state.pagado)
                                                        ?
                                                        <div>
                                                            <div>
                                                                <Grid container justify="space-between" style={{marginBottom: "10px"}}>
                                                                    <Grid>
                                                                        <div style={{display: "inline-flex"}}>
                                                                            <RadioButtonCheckedIcon style={{color: "#f50057", marginRight: "5px"}}/>
                                                                            <Typography variant="body1" component="p">
                                                                                Pago realizado
                                                                            </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <img src={Tarjetas} alt="Tarjetas de credito"/>
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider />
                                                            </div>
                                                            <div>
                                                                <Grid container justify="space-between" style={{marginBottom: "10px", marginTop: "15px"}}>
                                                                    <Grid>
                                                                        <Typography variant="h6" component="p">
                                                                            Total
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <Typography variant="h6" component="p">
                                                                            S/ {this.state.montoPago}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider />
                                                            </div>
                                                        </div>
                                                        :
                                                        <div>
                                                            <div>
                                                                <Grid container justify="space-between" style={{marginBottom: "10px"}}>
                                                                    <Grid>
                                                                        <div style={{display: "inline-flex"}}>
                                                                            <RadioButtonCheckedIcon style={{color: "#f50057", marginRight: "5px"}}/>
                                                                            <Typography variant="body1" component="p">
                                                                                Pagar con tarjeta
                                                                            </Typography>
                                                                        </div>
                                                                    </Grid>
                                                                    <Grid>
                                                                        <img src={Tarjetas} alt="Tarjetas de credito"/>
                                                                    </Grid>
                                                                </Grid>
                                                                <Divider />
                                                            </div>
                                                            {
                                                                /**
                                                                 * Mostrar el metodo de pago si la solicitud es de tipo usuario o empresa
                                                                 */
                                                            }
                                                            {
                                                                (this.state.tipoSolicitud === "USUARIO" || (this.state.tipoSolicitud === "EMPRESA" && !this.state.empresaDeConfianza))?
                                                                <div>
                                                                    {
                                                                        (this.state.solicitudSePuedePagar)?
                                                                        <div>
                                                                            {
                                                                                (this.state.errorPagoCulqi)?
                                                                                    <div>
                                                                                        <Typography variant="body1" component="p" style={{color: "#f44336", marginTop: "5px", marginBottom: "5px"}}>
                                                                                            Hubo un error al realizar el pago, revise su saldo y vuelva a intentar
                                                                                        </Typography>
                                                                                        <Divider />
                                                                                    </div>
                                                                                :<></>
                                                                            }
                                                                            <div style={{marginTop: "15px", marginBottom: "10px"}}>
                                                                                <CulqiProvider
                                                                                    publicKey="pk_live_a4a89c8eac931831"
                                                                                    amount={0}
                                                                                    title={this.state.nombre}
                                                                                    description={this.state.detalle}
                                                                                    onToken={token => {
                                                                                        const datos = {
                                                                                            solicitudCod: this.state.solicitudCod, 
                                                                                            monto: this.state.montoPagoCulqi,
                                                                                            descripcion: this.state.nombre,
                                                                                            correo: token.email,
                                                                                            token: token.id
                                                                                        }
                                                                                        /**
                                                                                         * Pagar solicitud
                                                                                         */
                                                                                        this.pagarSolicitud(datos);

                                                                                    }}
                                                                                    onError={error => {
                                                                                        this.setState({errorPagoCulqi: true});
                                                                                    }}
                                                                                    // Uncomment `options` to see customizations take place
                                                                                    options={{
                                                                                        style: {
                                                                                            maintext: "black",
                                                                                            desctext: "black"
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    <div>
                                                                                        <Culqi>
                                                                                            {({ openCulqi, setAmount, amount }) => {
                                                                                            return (
                                                                                                <div>
                                                                                                    <Grid container justify="space-between">
                                                                                                        <Grid>
                                                                                                            <FormControl component="fieldset">
                                                                                                                <RadioGroup
                                                                                                                    aria-label="gender"
                                                                                                                    name="gender1"
                                                                                                                    value={this.state.valorDefaultRadioGroup}
                                                                                                                    onChange={e => {
                                                                                                                            const valor = e.target.value;
                                                                                                                            this.setRadioGroupValue(valor);
                                                                                                                            const totalNumber = Number(valor).toFixed(2);
                                                                                                                            const totalCentimos = (totalNumber * 100).toFixed(2);
                                                                                                                            const totalCentimosNumber = Number(totalCentimos);
                                                                                                                            setAmount(totalCentimosNumber);
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <FormControlLabel   value={this.state.total}
                                                                                                                                        control={<Radio />}
                                                                                                                                        label={`S/ ${this.state.total}`}/>
                                                                                                                </RadioGroup>
                                                                                                            </FormControl>
                                                                                                        </Grid>
                                                                                                        <Grid>
                                                                                                            <div style={{position: "relative"}}>
                                                                                                                <Button variant="contained" color="secondary"  onClick={openCulqi} disabled={this.state.disabeldBtnCulqi}> 
                                                                                                                    Pagar con Culqi
                                                                                                                </Button>
                                                                                                                {this.state.abrirProgreso && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                                                                                                            </div>
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </div>
                                                                                            );
                                                                                            }}
                                                                                        </Culqi>
                                                                                    </div>
                                                                                </CulqiProvider>
                                                                            </div>
                                                                            <Divider />
                                                                            <div style={{marginTop: "20px"}}>
                                                                                <div style={{display: "inline-flex", justifyContent: "center", width: "100%"}}>
                                                                                    <LockOutlinedIcon style={{color: "grey"}}/>
                                                                                    <Typography variant="body1" component="p" color="textSecondary" style={{marginTop: "1px", marginLeft: "5px"}}>
                                                                                        Pago 100% seguro, NO guardamos tus datos
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div>
                                                                            <div style={{marginTop: "15px", marginBottom: "10px"}}>
                                                                                <Grid container justify="space-between" style={{marginBottom: "10px"}}>
                                                                                    <Grid>
                                                                                        <Typography variant="body1" component="p" style={{marginTop: "5px"}}>
                                                                                            S/ {this.state.total}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                    <Grid>
                                                                                        <Button variant="contained"
                                                                                                color="secondary"
                                                                                                disabled={true}> 
                                                                                            Pagar con Culqi
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                            <Divider />
                                                                            {
                                                                                (!this.state.solicitudPrecioConfirmado)?
                                                                                <div style={{marginTop: "20px"}}>
                                                                                    <div style={{display: "inline-flex", width: "100%"}}>
                                                                                        <ReportProblemOutlinedIcon style={{color: "#FAA634"}}/>
                                                                                        <Typography variant="body1" component="p" color="textSecondary" style={{marginTop: "1px", marginLeft: "5px"}}>
                                                                                            Precio no confirmado, para pagar, por favor comuníquese con el central (958310831)
                                                                                        </Typography>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                            }
                                                                            {
                                                                                (!this.state.solicitudSubtrabajosFinalizados)?
                                                                                <div style={{marginTop: "20px"}}>
                                                                                    <div style={{display: "inline-flex", width: "100%"}}>
                                                                                        <ReportProblemOutlinedIcon style={{color: "#FAA634"}}/>
                                                                                        <Typography variant="body1" component="p" color="textSecondary" style={{marginTop: "1px", marginLeft: "5px"}}>
                                                                                            Pronto daremos solución a su problema, para pagar, por favor espere a que terminemos
                                                                                        </Typography>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                <></>
                                                                            }
                                                                            <div style={{marginTop: "20px"}}>
                                                                                <div style={{display: "inline-flex", justifyContent: "center", width: "100%"}}>
                                                                                    <LockOutlinedIcon style={{color: "grey"}}/>
                                                                                    <Typography variant="body1" component="p" color="textSecondary" style={{marginTop: "1px", marginLeft: "5px"}}>
                                                                                        Pago 100% seguro, NO guardamos tus datos
                                                                                    </Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                :
                                                                <div>
                                                                    <MuiThemeProvider theme={theme}>
                                                                        <Typography variant="body1" component="h2" style={{marginTop: "15px", marginBottom: "15px"}}>
                                                                            {this.state.tipoSolicitud} {this.state.nombreEmpresa}
                                                                        </Typography>
                                                                    </MuiThemeProvider>
                                                                    <Divider />
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                {/**
                                 * Seccion Direccion
                                 */}
                                <div style={{marginTop: "30px"}}>
                                    <Card>
                                        <CardContent>
                                            <div>
                                                <div style={{display: "inline-flex"}}>
                                                    <HomeIcon style={{color: "#3B3092", marginTop: "2px", marginRight: "5px"}}/>
                                                    <MuiThemeProvider theme={theme}>
                                                        <Typography variant="h5" component="h2">
                                                            Dirección
                                                        </Typography>
                                                    </MuiThemeProvider>
                                                </div>
                                            </div>
                                            <div style={{marginTop: "20px"}}>
                                                <Grid container justify="space-between" style={{marginBottom: "10px"}}>
                                                    <Grid>
                                                        <Typography variant="body1" component="p">
                                                            Ubicación
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </div>
                                            <div>
                                                <Typography variant="body1" component="p" style={{marginTop: "15px", marginBottom:"15px"}}>
                                                    {(this.state.direccion === null || this.state.direccion === undefined)? "": this.state.direccion.toUpperCase()} - {(this.state.referencia === null || this.state.referencia === undefined)? "" : this.state.referencia.toUpperCase()}
                                                </Typography>
                                                <Divider />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                {/**
                                 * Seccion Hora de atencion
                                 */}
                                <div style={{marginTop: "30px"}}>
                                    <Card>
                                        <CardContent>
                                            <div>
                                                <div style={{display: "inline-flex"}}>
                                                    <WatchLaterIcon style={{color: "#3B3092", marginRight: "5px"}}/>
                                                    <MuiThemeProvider theme={theme}>
                                                        <Typography variant="h5" component="h2">
                                                            Hora
                                                        </Typography>
                                                    </MuiThemeProvider>
                                                </div>
                                            </div>
                                            <div style={{marginTop: "20px"}}>
                                                <Grid container justify="space-between" style={{marginBottom: "10px"}}>
                                                    <Grid>
                                                        <Typography variant="body1" component="p">
                                                            Fecha y hora programada
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </div>
                                            <div>
                                                {
                                                (this.state.asignado)?
                                                    <Typography variant="body1" component="p" style={{marginTop: "15px", marginBottom:"15px"}}>
                                                        {this.state.horaAtencion}
                                                    </Typography>
                                                :
                                                <div>
                                                    {
                                                        (this.state.cancelado)?
                                                        <Typography variant="body1" component="p" style={{marginTop: "15px", marginBottom:"15px", color: "#f44336"}}>
                                                            SOLICITUD CANCELADA
                                                        </Typography>
                                                        :
                                                        <Typography variant="body1" component="p" style={{marginTop: "15px", marginBottom:"15px", color: "grey"}}>
                                                            PRONTO SE LE ASIGANRÁ UNA FECHA
                                                        </Typography>
                                                    }
                                                </div>
                                                }
                                                <Divider />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                {/**
                                 * Datos del tecnico
                                 */}
                                <div style={{marginTop: "30px"}}>
                                    <Card>
                                        <CardContent>
                                            <div>
                                                <div style={{display: "inline-flex"}}>
                                                    <PersonIcon style={{color: "#3B3092", marginRight: "5px"}}/>
                                                    <MuiThemeProvider theme={theme}>
                                                        <Typography variant="h5" component="h2">
                                                            Técnico
                                                        </Typography>
                                                    </MuiThemeProvider>
                                                </div>
                                            </div>
                                            <div style={{marginTop: "20px"}}>
                                                <Grid container justify="space-between" style={{marginBottom: "10px"}}>
                                                    <Grid>
                                                        <Typography variant="body1" component="p">
                                                            Datos del técnico asignado
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </div>
                                            <div>
                                                <div style={{marginTop: "15px", marginBottom: "15px"}}>
                                                {
                                                    (this.state.asignado)?
                                                        <div>
                                                            <CardTecnico    imagen={(this.state.tecnicoFotoUrl === "" || this.state.tecnicoFotoUrl === null)? ImagenTecnicoDefaul : this.state.tecnicoFotoUrl}
                                                                            nombre={this.state.nombreTecnico} 
                                                                            especialidades={this.state.especialidad}
                                                                            edad={this.state.edad}
                                                                            nroDocumento={this.state.nroDocumento}
                                                                            celular={this.state.celular}
                                                                            calificacion={(this.state.calificacion < 3)? 3 : this.state.calificacion }/>
                                                            <div style={{marginTop: "20px"}}>
                                                                <Divider />
                                                                    <Grid container justify="space-between" style={{marginBottom: "15px", marginTop: "15px"}}>
                                                                        <Grid>
                                                                            <Typography variant="body1" component="p">
                                                                                Calificar al técnico
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid>
                                                                            <HowToRegIcon style={{color: "#3B3092"}}/>
                                                                        </Grid>
                                                                    </Grid>
                                                                <Divider />
                                                            </div>
                                                            <div>
                                                                <CardCalificar solicitudCodigo={this.state.solicitudCod}/>
                                                            </div>
                                                        </div>
                                                    :
                                                    <div>
                                                    {
                                                        (this.state.cancelado)?
                                                        <Typography variant="body1" component="p" style={{color: "#f44336"}}>
                                                            SOLICITUD CANCELADA
                                                        </Typography>
                                                        :
                                                        <Typography variant="body1" component="p" style={{color: "grey"}}>
                                                            PRONTO SE LE ASIGNARÁ UN TECNIGO
                                                        </Typography>
                                                    }
                                                    </div>
                                                }
                                                </div>
                                                <Divider />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>
                                 {/**
                                 * Descargar informe
                                 */}
                                <div style={{marginTop: "30px", marginBottom: "50px"}}>
                                    <Card>
                                        <CardContent>
                                            <div>
                                                <div style={{display: "inline-flex"}}>
                                                    <DescriptionIcon style={{color: "#3B3092", marginRight: "5px"}}/>
                                                    <MuiThemeProvider theme={theme}>
                                                        <Typography variant="h5" component="h2">
                                                            Informe
                                                        </Typography>
                                                    </MuiThemeProvider>
                                                </div>
                                            </div>
                                            <div style={{marginTop: "20px"}}>
                                                <Grid container justify="space-between" style={{marginBottom: "10px"}}>
                                                    <Grid>
                                                        <Typography variant="body1" component="p">
                                                            Informe de la solicitud
                                                        </Typography>
                                                    </Grid>
                                                    <Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider />
                                            </div>
                                            <div>
                                                <div style={{marginTop: "15px", marginBottom: "15px"}}>
                                                    {
                                                        (this.state.solicitudEstado === 5)?
                                                        <LinkMaterial   href={this.state.pdfUrl}
                                                                        target="_blank" 
                                                                        className="solicitudDetBotonInforme"
                                                                        underline="none">
                                                            <GetAppIcon  style={{marginBottom: "-7px", marginRight: "5px"}}/> INFORME
                                                        </LinkMaterial>
                                                        :
                                                        <Typography variant="body1" component="p" style={{color: "grey"}}>
                                                            <GetAppIcon  style={{marginBottom: "-7px", marginRight: "5px"}}/> INFORME
                                                        </Typography>
                                                    }
                                                </div>
                                                <Divider />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </div>

                            </Grid>
                        </Grid>
                    </div>
                    {/**
                     * Fin de contenedor General
                     */}
            </Container>
            {/**
             * Mensaje resultado
             */}
            <Snackbar   open={this.state.openMessage}
                        autoHideDuration={10000}
                        onClose={this.closeMessage}>
                <Alert  onClose={this.closeMessage}
                        severity={this.state.tipoMessage}>
                    {this.state.textoMessage}
                </Alert>
            </Snackbar>
        </Layout>
        );
    }
}
 
export default withRouter(SolicitudDetalle);
  
