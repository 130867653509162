import React, { Component } from 'react';
import './ModalNewDireccion.css';
import { withStyles } from '@material-ui/core/styles';
import axios from '../../utils/axios';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import { responsiveFontSizes, MuiThemeProvider, createMuiTheme } from '@material-ui/core';

import {Grid,
        LinearProgress,
        Dialog,
        Typography,
        IconButton,
        Button
    } from '@material-ui/core';

import FormNewDireccion from './FormNewDireccion';
import StaticMap from './StaticMap';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});
  
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

class ModalNewDireccionDos extends Component {

    constructor(props){
        super(props)
        this.state = {
            latitud: 0,
            longitud: 0,
            direccion: "",
            referencia: "",
            calle: "",
            pais: null,
            ciudad: null,
            provincia: null,
            distrito: "",
            dataIncorrecta: false,
            hiddenLoadingDireccion: true,
            disabledBtnNewDireccion: true,
            errorProvinciaCiudadPais: false
        }
    }
    setLatitud = (valor) => {
        this.setState({latitud: valor});
    }
    setLongitud = (valor) => {
        this.setState({longitud: valor});
    }
    setDireccion = (valor) => {
        this.setState({direccion: valor});
    }
    setReferencia = (valor) => {
        this.setState({referencia: valor});
    }
    setCalle = (valor) => {
        this.setState({calle: valor});
    }
    setPais = (valor) => {
        this.setState({pais: valor});
    }
    setCiudad = (valor) => {
        this.setState({ciudad: valor});
    }
    setProvincia = (valor) => {
        this.setState({provincia: valor});
    }
    setDistrito = (valor) => {
        this.setState({distrito: valor});
    }
    clearErrorMessage = () => {
        this.setState({
            errorProvinciaCiudadPais: false,
            dataIncorrecta: false,
        });
    }
    cerrarModal = () => {
        /**
         * Limpiar los valores
         */
        this.setState({
            latitud: 0,
            longitud: 0,
            direccion: "",
            referencia: "",
            calle: "",
            pais: null,
            ciudad: null,
            provincia: null,
            distrito: "",
            dataIncorrecta: false,
            hiddenLoadingDireccion: true,
            disabledBtnNewDireccion: true,
            errorProvinciaCiudadPais: false
        });
        this.props.ocultarModalNewDirecciones();
    }
    /**
     * Habilitar el boton crear direccion
     */
    habilitarBotonAgregarDireccion = () => {
        this.setState({disabledBtnNewDireccion: false});
    }
    /**
     * Deshabilitar el boton crear direccion
     */
    deshabilitarBotonAgregarDireccion = () => {
        this.setState({disabledBtnNewDireccion: true});
    }
    /**
     * Mostrar linear progress
     */
    mostrarLinearProgress = () => {
        this.setState({hiddenLoadingDireccion: false});
    }
    /**
     * Ocultar linear progress
     */
    ocultarLinearProgress = () => {
        this.setState({hiddenLoadingDireccion: true});
    }
    /**
     * Guardar direccion
     */
    guardarDireccion = () => {
        if( this.state.latitud === 0 || this.state.longitud === 0
            || this.state.pais === null || this.state.ciudad === null || this.state.provincia === null){
            this.setState({errorProvinciaCiudadPais: true, disabledBtnNewDireccion: true});
        }else{
            this.setState({hiddenLoadingDireccion:false, disabledBtnNewDireccion: true});
            const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
            const dataUser = localStorage.getItem('data_user');
            const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
            const datos = {
                pais: (this.state.pais)? this.state.pais.toUpperCase(): "",
                ciudad: (this.state.ciudad)? this.state.ciudad.toUpperCase(): "",
                provincia: (this.state.provincia)? this.state.provincia.toUpperCase(): "",
                distrito: (this.state.distrito)? this.state.distrito.toUpperCase(): "",
                calle: (this.state.calle)? this.state.calle.toUpperCase(): "",
                nombre: (this.state.direccion)? this.state.direccion.toUpperCase(): "",
                referencia: (this.state.referencia)? this.state.referencia.toUpperCase(): "",
                lat: this.state.latitud,
                lon: this.state.longitud,
                usuarioCod: usuarioCod
            }
            axios.post('usuario/direcciones', datos,{ headers: { Authorization: authString } })
            .then(res =>{
                /**
                 * Actualizar la lista de direcciones
                 */
                this.props.getDireccionesUsuario();
                this.setState({
                    latitud: 0,
                    longitud: 0,
                    direccion: "",
                    referencia: "",
                    calle: "",
                    pais: null,
                    ciudad: null,
                    provincia: null,
                    distrito: "",
                    dataIncorrecta: false,
                    hiddenLoadingDireccion: true,
                    disabledBtnNewDireccion: true,
                    errorProvinciaCiudadPais: false
                });
                this.props.ocultarModalNewDirecciones();
            }).catch(error => {
                this.setState({dataIncorrecta:true});
                this.setState({hiddenLoadingDireccion:true, disabledBtnNewDireccion: true});
            });
        }
    }

    render() {
        return (
            <Dialog open={this.props.openModalNewDireccion}
                    fullWidth={true}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}>
                <LinearProgress color="secondary" hidden={this.state.hiddenLoadingDireccion}/>
                <DialogTitle onClose={this.cerrarModal}>
                    <div>
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="h5" component="h2" style={{fontWeight: 'bold'}}>
                                Agregar dirección
                            </Typography>
                        </MuiThemeProvider>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container>
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="h5" component="p" style={{margin: "5px", marginBottom: "20px"}}>
                                Buscar por calle, ciudad, distrito y seleccionar una dirección
                            </Typography>
                        </MuiThemeProvider>
                        {
                            (this.state.dataIncorrecta)?
                                <Typography variant="subtitle2" component="p" style={{color: "#f44336", marginBottom: "20px"}}>
                                    Ubicación sin cobertura, por hora estamos en la ciudad de Arequipa
                                </Typography>
                            :<></>
                        }
                    </Grid>
                    <Grid container direction="row" spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <FormNewDireccion 
                                setLatitud={this.setLatitud}
                                setLongitud={this.setLongitud}
                                direccion={this.state.direccion}
                                setDireccion={this.setDireccion}
                                calle={this.state.calle}
                                setCalle={this.setCalle}
                                setReferencia={this.setReferencia}
                                referencia={this.state.referencia}
                                setPais={this.setPais}
                                setCiudad={this.setCiudad}
                                setProvincia={this.setProvincia}
                                setDistrito={this.setDistrito}
                                errorProvinciaCiudadPais={this.state.errorProvinciaCiudadPais}
                                clearErrorMessage={this.clearErrorMessage}
                                habilitarBotonAgregarDireccion={this.habilitarBotonAgregarDireccion}
                                deshabilitarBotonAgregarDireccion={this.deshabilitarBotonAgregarDireccion}
                                mostrarLinearProgress={this.mostrarLinearProgress}
                                ocultarLinearProgress={this.ocultarLinearProgress}
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className="modalNewDireccionMapa">
                                <StaticMap  lat={this.state.latitud}
                                            lon={this.state.longitud} />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container direccion="row" spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <div className="modalNewDireccionContenedorBoton">
                                    <Button variant="contained"
                                            color="primary"
                                            fullWidth
                                            onClick={this.guardarDireccion}
                                            disabled={this.state.disabledBtnNewDireccion}> 
                                        AGREGAR
                                    </Button>
                            </div>
                            <br></br>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        )
    }

}

export default ModalNewDireccionDos