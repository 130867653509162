import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Card,
        CardActionArea,
        CardMedia,
        CardContent,
        CardActions,
        Button,
        Typography
    } from '@material-ui/core';

class CardVideo extends React.Component {
    render(){
        return(
            <Card className={this.props.classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={this.props.classes.media}
                        image={this.props.imagen}
                        title={this.props.tituloImagen}
                    />
                    <CardContent style={{height: "90px"}}>
                        <Typography variant="subtitle2" component="p">
                            {this.props.titulo}
                        </Typography>
                        <Typography variant="body2" component="p" style={{marginTop: "5px"}}>
                            {this.props.descripcion}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    <Button onClick={() => this.props.mivideo(this.props.objVideo)} size="small" color="primary">
                        Ver video
                    </Button>
                </CardActions>
            </Card>
        )
    }
}

export default withStyles(theme => ({
    root: {
        width: '100%'
    },
    media: {
        height: 140
    },
}))(CardVideo)