import React from 'react';
import './CardCalificar.css';
import axios from '../../utils/axios';

import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';

import EmogiLLorando from '../../assets/img/icons/emogiLlorando.svg';
import EmogiTriste from '../../assets/img/icons/emogiTriste.svg';
import EmogiRegular from '../../assets/img/icons/emogiRegular.svg';
import EmogiContento from '../../assets/img/icons/emogiContento.svg';
import EmogiFeliz from '../../assets/img/icons/emogiFeliz.svg';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import Alerta from '../general/Alerta';

export default class CardCalificar extends React.Component {
    constructor(props){
        super(props)
        this.state={
            emogiLlorandoColor: false,
            emogiTristeColor: false,
            emogiRegular: false,
            emogiContentoColor: false,
            emogiFelizColor: false,
            calificarMensaje: "",
            estadoFormularioCalificar: false,
            numeroCalificacion: 0,
            hiddenLoadingCardCalificar: true,
            disabledButtonCancelar: false,
            disabledButtonEnviar: false,
            alertaCalificarTexto: "",
            alertaCalificarMostrar: false,
            alertaCalificarTipo: "success",
        }
    }
    seleccionarEmogiLLorando = () => {
        this.setState({
            emogiLlorandoColor: true,
            emogiTristeColor: false,
            emogiRegular: false,
            emogiContentoColor: false,
            emogiFelizColor: false,
            estadoFormularioCalificar: true,
            numeroCalificacion: 1
        });
    }
    seleccionarEmogiTriste = () => {
        this.setState({
            emogiLlorandoColor: false,
            emogiTristeColor: true,
            emogiRegular: false,
            emogiContentoColor: false,
            emogiFelizColor: false,
            estadoFormularioCalificar: true,
            numeroCalificacion: 2
        });
    }
    seleccionarEmogiRegular = () => {
        this.setState({
            emogiLlorandoColor: false,
            emogiTristeColor: false,
            emogiRegular: true,
            emogiContentoColor: false,
            emogiFelizColor: false,
            estadoFormularioCalificar: true,
            numeroCalificacion: 3
        });
    }
    seleccionarEmogiContento = () => {
        this.setState({
            emogiLlorandoColor: false,
            emogiTristeColor: false,
            emogiRegular: false,
            emogiContentoColor: true,
            emogiFelizColor: false,
            estadoFormularioCalificar: true,
            numeroCalificacion: 4
        });
    }
    seleccionarEmogiFeliz = () => {
        this.setState({
            emogiLlorandoColor: false,
            emogiTristeColor: false,
            emogiRegular: false,
            emogiContentoColor: false,
            emogiFelizColor: true,
            estadoFormularioCalificar: true,
            numeroCalificacion: 5
        });
    }
    cancelarFormCalificar = () => {
        this.setState({
            emogiLlorandoColor: false,
            emogiTristeColor: false,
            emogiRegular: false,
            emogiContentoColor: false,
            emogiFelizColor: false,
            calificarMensaje:"",
            estadoFormularioCalificar: false,
            numeroCalificacion: 0
        });
    }
    /**
     * Set calificar mensaje
     */
    setCalificarMensaje = (valor) => {
        this.setState({calificarMensaje: valor.target.value});
    }
    /**
     * Enviar calificacion
     */
    enviarCalificacion = () => {
        const solicitudCod = this.props.solicitudCodigo;
        let validado = true;
        /**
         * Validar solicitud cod
         */
        if(solicitudCod <= 0 || solicitudCod === undefined || solicitudCod === null){
            this.mostrarAlertaCalificar("error", "Error, puede recargar la página");
            validado = false;
        }
        /**
         * Validatar numero de calificacion
         */
        if(this.state.numeroCalificacion <= 0){
            this.mostrarAlertaCalificar("error", "Error, elegir una calificación");
            validado = false;
        }
        /**
         * Validar el tamanio del mensaje
         */
        if(this.state.calificarMensaje.length > 500){
            this.mostrarAlertaCalificar("error", "Máximo 500 carácteres");
            validado = false;
        }
        /**
         * Enviar Calificacion
         */
        if(validado){
            this.setState({
                hiddenLoadingCardCalificar: false,
                disabledButtonCancelar: true,
                disabledButtonEnviar: true });
            const datos = {
                solicitudCod: solicitudCod,
                puntos: this.state.numeroCalificacion,
                mensaje: this.state.calificarMensaje
            }
            const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
            axios.post('usuario/calificaciones', datos,  { headers: { Authorization: authString } })
                .then(response =>{
                    this.setState({
                        emogiLlorandoColor: false,
                        emogiTristeColor: false,
                        emogiRegular: false,
                        emogiContentoColor: false,
                        emogiFelizColor: false,
                        calificarMensaje:"",
                        estadoFormularioCalificar: false,
                        numeroCalificacion: 0,
                        hiddenLoadingCardCalificar: true,
                        disabledButtonCancelar: false,
                        disabledButtonEnviar: false
                    });
                    this.mostrarAlertaCalificar("success", "¡Muchas gracias por enviarnos tu calificación!");
                }).catch(error => {
                    this.setState({
                        emogiLlorandoColor: false,
                        emogiTristeColor: false,
                        emogiRegular: false,
                        emogiContentoColor: false,
                        emogiFelizColor: false,
                        calificarMensaje:"",
                        estadoFormularioCalificar: false,
                        numeroCalificacion: 0,
                        hiddenLoadingCardCalificar: true,
                        disabledButtonCancelar: false,
                        disabledButtonEnviar: false
                    });
                    this.mostrarAlertaCalificar("error", "Error, por favor recargue la página y vuelva a enviar");               
                });
        }
    }
    /**
     * Cerrar Alerta
     */
    cerrarAlertaCalificar = () => {
        this.setState({
            alertaCalificarTexto: "",
            alertaCalificarMostrar: false,
            alertaCalificarTipo: this.state.alertaCalificarTipo
        });
    }
    /**
     * Mostrar Alerta
     */
    mostrarAlertaCalificar = (tipo, texto) => {
        this.setState({
            alertaCalificarTexto: texto,
            alertaCalificarMostrar: true,
            alertaCalificarTipo: tipo
        });
    }

    render(){
        return (
            <div>
                <LinearProgress color="secondary" hidden={this.state.hiddenLoadingCardCalificar}/>
                <div style={{display:"inline-flex", justifyContent: "center", width:"100%", height: "40px", marginTop: "15px"}}>
                    {
                        (!this.state.emogiLlorandoColor)?
                        <button className="cardCalificarEmogiColorGris" onClick={this.seleccionarEmogiLLorando}>
                            <img src={EmogiLLorando} width="24" height="24" alt="Calificar tecnigo"/>
                        </button>     
                        :
                        <div className="cardCalificarEmogiColor">
                            <img src={EmogiLLorando} width="32" height="32" alt="Calificar tecnigo"/>
                        </div>
                    }
                    {
                        (!this.state.emogiTristeColor)?
                        <button className="cardCalificarEmogiColorGris" onClick={this.seleccionarEmogiTriste}>
                            <img src={EmogiTriste} width="24" height="24" alt="Calificar tecnigo"/>
                        </button>     
                        :
                        <div className="cardCalificarEmogiColor">
                            <img src={EmogiTriste} width="32" height="32" alt="Calificar tecnigo"/>
                        </div>
                    }
                    {
                        (!this.state.emogiRegular)?
                        <button className="cardCalificarEmogiColorGris" onClick={this.seleccionarEmogiRegular}>
                            <img src={EmogiRegular} width="24" height="24" alt="Calificar tecnigo"/>
                        </button>     
                        :
                        <div className="cardCalificarEmogiColor">
                            <img src={EmogiRegular} width="32" height="32" alt="Calificar tecnigo"/>
                        </div>
                    }
                    {
                        (!this.state.emogiContentoColor)?
                        <button className="cardCalificarEmogiColorGris" onClick={this.seleccionarEmogiContento}>
                            <img src={EmogiContento} width="24" height="24" alt="Calificar tecnigo"/>
                        </button>     
                        :
                        <div className="cardCalificarEmogiColor">
                            <img src={EmogiContento} width="32" height="32" alt="Calificar tecnigo"/>
                        </div>
                    }
                    {
                        (!this.state.emogiFelizColor)?
                        <button className="cardCalificarEmogiColorGris" onClick={this.seleccionarEmogiFeliz}>
                            <img src={EmogiFeliz} width="24" height="24" alt="Calificar tecnigo"/>
                        </button>     
                        :
                        <div className="cardCalificarEmogiColor">
                            <img src={EmogiFeliz} width="32" height="32" alt="Calificar tecnigo"/>
                        </div>
                    }
                </div>
                <Alerta texto={this.state.alertaCalificarTexto}
                        mostrarAlerta={this.state.alertaCalificarMostrar}
                        tipoAlerta={this.state.alertaCalificarTipo}
                        cerrarAlerta = {this.cerrarAlertaCalificar}/>

                <Collapse in={this.state.estadoFormularioCalificar}>
                    <Grid container>
                        <Grid item md={2}></Grid>
                        <Grid item xs={12} md={8}>
                            <div style={{marginBottom: "10px", marginTop: "15px"}}>
                                <Grid container>
                                    <Grid item>
                                        <ChatOutlinedIcon style={{marginTop: "5px", fontSize: 22, color: "#455a64"}}/>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body1" component="p" style={{margin: "5px", color: "#455a64"}}>
                                            Mensaje (opcional):
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <TextField
                                    id="calificarMensaje"
                                    size="small"
                                    variant="outlined"
                                    placeholder="Tu mensaje"
                                    multiline
                                    rows={4}
                                    fullWidth
                                    value={this.state.calificarMensaje}
                                    onChange={this.setCalificarMensaje}/>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Grid item>
                                        <Button variant="outlined" size="small" color="primary" 
                                                onClick={this.cancelarFormCalificar}
                                                disabled={this.state.disabledButtonCancelar}
                                                style={{marginLeft: "1px", marginTop:"15px"}}>
                                            Cancelar
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" size="small" color="primary"
                                                onClick={this.enviarCalificacion}
                                                disabled={this.state.disabledButtonEnviar}
                                                style={{marginRight: "1px", marginTop:"15px"}}>
                                            Enviar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item md={2}></Grid>
                    </Grid>
                </Collapse>
            </div>
          );
    }
}