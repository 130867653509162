import React from 'react';
import axios from '../utils/axios';
import './Reporte.css';

import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import LetraColorSimbolo from '../assets/img/icons/letraColorSimbolo.svg';

import Layout from '../components/layout/Layout';
import FormSolicitudesRealizadas from '../components/reporte/FormSolicitudesRealizadas';
import TableSolicitudesRealizadas from '../components/reporte/TableSolicitudesRealizadas';
import BotonVerde from '../components/general/BotonVerde';

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default class Reporte extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            listaSolicitudes: [],
            totalMinutos: '',
            fechaDesde: '',
            fechaHasta: '',
            disabledBtnExportarPdf: false,
            openMessage: false,
            tipoMessage: "success",
            textoMessage: ""
        }
      
    }

    /**
     * Exporta y descargar a pdf
     */
    exportarYDescargarPdf = () => {
        // Deshabilitar boton
        this.deshabilitarBtnExportarPdf(true);

        const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
        const dataUser = localStorage.getItem('data_user');
        const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
        const datos = {
            usuarioCod: usuarioCod,
            tiempoTotalTrabajo: this.state.totalMinutos,
            fechaDesde: this.state.fechaDesde,
            fechaHasta: this.state.fechaHasta,
            solicitudesAtendidos: this.state.listaSolicitudes
        }
        axios.post('usuario/solicitudes/atendidos/exportar/a/pdf', datos,{ headers: { Authorization: authString }, responseType: 'blob' })
            .then(res => {
                // Deshabilitar boton
                this.deshabilitarBtnExportarPdf(false);
                //Create a Blob from the PDF Stream
                const file = new Blob([res.data], {type: 'application/pdf'});
                //Build a URL from the file
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            })
            .catch(error => {
                // Deshabilitar boton
                this.deshabilitarBtnExportarPdf(false);
                // Manejo de errores
                if(error.response){
                    this.showMessage("error", "Hubo un problema al exportar el reporte");
                }else{
                    this.showMessage("warning", "No tiene conexión a internet");
                }
            });
    }
    /**
     * Set lista solicitudes
     */
    setListaSolicitudes = (val) => {
        this.setState({listaSolicitudes: val});
    }
    /**
     * Set total minutos
     */
    setTotalMinutos = (val) => {
        this.setState({totalMinutos: val});
    }
    /**
     * Set fecha desde
     */
    setFechaDesde = (val) => {
        this.setState({fechaDesde: val});
    }
    /**
     * Set fecha hasta
     */
    setFechaHasta = (val) => {
        this.setState({fechaHasta: val});
    }
    /**
     * Deshabilitar el boton exportar a pdf
     */
    deshabilitarBtnExportarPdf = (valor) => {
        this.setState({disabledBtnExportarPdf: valor});
    }
    /**
     * Message
     */
     showMessage(tipo, texto){
        this.setState({openMessage: true, tipoMessage: tipo, textoMessage: texto});
    }
    closeMessage = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openMessage: false});
    }

    render(){
        return(
            <Layout>
                <div className="reporteContenedorDegradado">
                    <Container>
                        <div className="reporteContenedorEncabezado">
                            <div style={{marginTop: '40px'}}>
                                <div style={{height: '180px'}}>
                                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                                        <img    src={LetraColorSimbolo}
                                                className="reporteCirculo"
                                                alt="TecniGo"/>
                                    </div>
                                </div>
                            </div>
                            <div className="reporteContenedorTitulo">
                                <h2 className="reporteEstiloTitulo">Reportes</h2>
                                <h2 className="reporteEstiloSubtitulo">De solicitud</h2>
                            </div>
                        </div>
                    </Container>
                </div>
                <Container>
                    <div style={{paddingTop: "20px", marginBottom: "20px"}}>
                        <div className="reporteContenedorTituloBotonExportar">
                            <div style={{marginBottom: "10px"}}>
                                <MuiThemeProvider theme={theme}>
                                    <Typography variant="h4" component="h2">
                                        Solicitudes atendidos
                                    </Typography>
                                </MuiThemeProvider>
                            </div>
                            <div className="reporteContenedorBotonExportar">
                                <div style={{position: 'relative'}}>
                                    <BotonVerde texto={"EXPORTAR A PDF"}
                                                type={"button"}
                                                disabledBtn={this.state.disabledBtnExportarPdf}
                                                eventoClick={this.exportarYDescargarPdf}
                                                icono={<SystemUpdateAltIcon />} />
                                    {this.state.disabledBtnExportarPdf && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                                </div>
                            </div>
                        </div>                         
                        <Divider/>
                    </div>
                    {
                        // Seccion form reporte solicitudes realizadas
                    }
                    <FormSolicitudesRealizadas  setListaSolicitudes={this.setListaSolicitudes}
                                                setTotalMinutos={this.setTotalMinutos}
                                                setFechaDesde={this.setFechaDesde}
                                                setFechaHasta={this.setFechaHasta} />
                    {
                        // Seccion table reporte solicitudes realizadas
                    }
                    <TableSolicitudesRealizadas listaSolicitudes={this.state.listaSolicitudes}
                                                totalMinutos={this.state.totalMinutos} />

                </Container>
                <br/><br/><br/><br/><br/>
                {/**
                 * Mensaje resultado
                 */}
                <Snackbar   open={this.state.openMessage}
                            autoHideDuration={10000}
                            onClose={this.closeMessage}>
                    <Alert  onClose={this.closeMessage}
                            severity={this.state.tipoMessage}>
                        {this.state.textoMessage}
                    </Alert>
                </Snackbar>
            </Layout>
        );
    }
}