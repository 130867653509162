import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';

import Credenciales from '../../utils/Credenciales';

export default class AutocompleteInput extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            address: ""
        }
    }

    handleChange = async address => {
        /**
         * Limpiar los datos anteriores
         */
        this.clearSelectDireccion();
        /**
         * Mostrar linear progress
         */
        this.props.mostrarLinearProgress();
        const results = await geocodeByAddress(address.value.description);
        const latLng = await getLatLng(results[0]);
        /**
         * Obtener los datos
         */
        this.props.setLatitud(latLng.lat);
        this.props.setLongitud(latLng.lng);
        this.props.setDireccion(results[0].formatted_address);
        results[0].address_components.map(valor => {
            if(valor.types[0] === "locality"){
                //Distrito
                this.props.setDistrito(valor.long_name);
            }
            if(valor.types[0] === "administrative_area_level_2"){
                //Provincia
                this.props.setProvincia(valor.long_name);
            }
            if(valor.types[0] === "administrative_area_level_1"){
                //Ciudad
                this.props.setCiudad(valor.long_name);
            }
            if(valor.types[0] === "country"){
                //Pais
                this.props.setPais(valor.long_name);
            }
            return valor;
        });
        /**
         * Ocultar linear progress
         */
        this.props.ocultarLinearProgress();
        /**
         * Habilitar el boton direccion
         */
        this.props.habilitarBotonAgregarDireccion();
        /**
         * Limpiar input search
         */
        this.setState({address: ""});
    }

    clearSelectDireccion = () => {
        /**
         * Deshabilitar el boton direccion
         */
        this.props.deshabilitarBotonAgregarDireccion();
        this.props.setLatitud(0);
        this.props.setLongitud(0);
        this.props.setDireccion("");
        this.props.setDistrito("");
        this.props.setProvincia(null);
        this.props.setCiudad(null);
        this.props.setPais(null);
        this.props.clearErrorMessage();
    };

    render(){
        return(
            <div>
                <GooglePlacesAutocomplete
                    apiKey={Credenciales.mapsKey}
                    autocompletionRequest={{
                        componentRestrictions: {
                            country: ['pe'],
                        }
                      }}
                    selectProps={{
                        value: this.state.address,
                        onChange: this.handleChange,
                      }}
                />
            </div>
        )
    }
}