import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import axios from '../../utils/axios';
import CloseIcon from '@material-ui/icons/Close';
import { responsiveFontSizes, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import {
        TextField,
        Dialog,
        IconButton,
        Typography,
        Grid
    } from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import BotonVerde from '../general/BotonVerde';
import { withSnackbar } from 'notistack';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
});
  
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
            </IconButton>
        ) : null}
        </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

let theme = createMuiTheme();
theme = responsiveFontSizes(theme);

class ModalNewEmpresa extends React.Component {
    
    constructor(props){
        super(props)
        this.state= {
            empresaNewNombre: "",
            empresaNewRuc: "",
            dataIncorrecta: false,
            rucInvalido: false,
            disabledBtnNewEmpresa: false
        }
    }
    /**
     * Ocultar modal
     */
    ocultarModal = () => {
        /**
         * Limpiar datos
         */
        this.setState({empresaNewNombre: "",
                        empresaNewRuc: "",
                        rucInvalido: false,
                        dataIncorrecta: false,
                        disabledBtnNewEmpresa: false});
        this.props.ocultarModalNewEmpresa();
    }
    /**
     * Set Nombre empresa
     */
    setNombreEmprea = (valor) => {
        this.setState({empresaNewNombre: valor.target.value});
    }
    /**
     * Set Ruc empresa
     */
    setRucEmpresa = (valor) => {
        this.setState({empresaNewRuc: valor.target.value});
    }
    /**
     * Crear empresa
     */
    crearEmpresa = () => {
        /**
         * Verificar los datos ingresados 
         */
        const nombre = this.state.empresaNewNombre;
        const ruc = this.state.empresaNewRuc;
        if(nombre === null || nombre === "" || ruc === null || ruc === "") {
            this.setState({dataIncorrecta: true,
                            rucInvalido: false});
        } else {
            /**
             * Verificar si el ruc es un numero y el numero de digitos (11)
             */
            const rucNumero = Number(ruc);
            if(rucNumero === undefined || rucNumero === null || isNaN(rucNumero) || ruc.length !== 11){
                this.setState({rucInvalido: true, dataIncorrecta: false});
            } else {
                /***
                 * Crear empresa
                 */
                this.setState({
                    disabledBtnNewEmpresa: true
                });
                const authString = 'Bearer '.concat(localStorage.getItem('tokenuser'));
                const dataUser = localStorage.getItem('data_user');
                const usuarioCod = JSON.parse(dataUser).data.usuarioCod;
                axios.get(`usuario/crear/empleado/empresa/${nombre}/ruc/${ruc}/usuario/${usuarioCod}`, { headers: { Authorization: authString } })
                .then(response =>{
                    /**
                     * Actualizar la lista de direcciones
                     */
                    this.props.getEmpresaUsuario();
                    this.props.enqueueSnackbar('Empresa agregada correctamente', {variant: 'success'});
                    this.setState({
                        empresaNewNombre: "",
                        empresaNewRuc: "",
                        dataIncorrecta: false,
                        rucInvalido: false,
                        disabledBtnNewEmpresa: false});
                    this.props.ocultarModalNewEmpresa();
                }).catch(error => {
                    this.props.enqueueSnackbar('Error al agregar', {variant: 'error'});
                    this.setState({
                        dataIncorrecta: true,
                        rucInvalido: false,
                        disabledBtnNewEmpresa: false});
                    this.props.ocultarModalNewEmpresa();
                });
            }
        }
    }
    
    render() {

        return (
            <Dialog onClose={this.ocultarModal} 
                    aria-labelledby="customized-dialog-title" 
                    open={this.props.openModalNewEmpresa}
                    fullWidth={true}
                    disableBackdropClick={true}
                    disableEscapeKeyDown={true}>
                <DialogTitle id="customized-dialog-title" onClose={this.ocultarModal}>
                    <div>
                        <MuiThemeProvider theme={theme}>
                            <Typography variant="h5" component="h2" style={{fontWeight: "bold"}}>
                                Agregar empresa
                            </Typography>
                        </MuiThemeProvider>
                    </div>
                </DialogTitle>
                <DialogContent dividers>
                    <div style={{marginBottom: "10px"}}>
                        <Grid container>
                            <Grid>
                                <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                    Razón social
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="subtitle2" component="p" style={{margin: "5px"}}>
                                    (Requerido)
                                </Typography>
                            </Grid>
                        </Grid>
                        <TextField
                            id="empresaNewNombre"
                            size="small"
                            variant="outlined"
                            fullWidth
                            value={this.state.empresaNewNombre}
                            onChange={this.setNombreEmprea}
                            InputProps={{
                                style: {
                                    backgroundColor: '#f2f2f2'
                                }
                            }}/>
                    </div>
                    <div style={{marginBottom: "10px", marginTop: "10px"}}>
                        <Grid container>
                            <Grid>
                                <Typography variant="body1" component="p" style={{margin: "5px"}}>
                                    RUC
                                </Typography>
                            </Grid>
                            <Grid>
                                <Typography variant="subtitle2" component="p" style={{margin: "5px"}}>
                                    (Requerido)
                                </Typography>
                            </Grid>
                        </Grid>
                        <TextField
                            id="empresaNewRuc"
                            size="small"
                            variant="outlined"
                            fullWidth
                            required
                            value={this.state.empresaNewRuc}
                            onChange={this.setRucEmpresa}
                            InputProps={{
                                maxLength: 11,
                                style: {
                                    backgroundColor: '#f2f2f2'
                                }
                            }}/>
                    </div>
                    <div style={{marginTop: "5px"}}>
                            {
                                (this.state.dataIncorrecta)?
                                    <Typography variant="subtitle2" component="p" style={{color: "#f44336"}}>
                                        Los campos son obligatorios
                                    </Typography>
                                :<></>
                            }
                            {
                                (this.state.rucInvalido)?
                                    <Typography variant="subtitle2" component="p" style={{color: "#f44336"}}>
                                        Número de RUC inválido
                                    </Typography>
                                :<></>
                            }
                    </div>
                    <div style={{textAlign:"center", marginTop: "30px", marginBottom: "15px", position: 'relative'}}>
                        <BotonVerde 
                          texto={"AGREGAR EMPRESA"}
                          disabledBtn={this.state.disabledBtnNewEmpresa}
                          eventoClick={this.crearEmpresa}
                        />
                        {this.state.disabledBtnNewEmpresa && <CircularProgress size={26} style={{color: "#00A775", position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px"}} />}
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

}

export default withSnackbar(ModalNewEmpresa);